import Input from 'components/Input/Input'
import { black } from 'constants/theme'
import styled from 'styled-components'
import { allowNumbersOnlyRegExp } from 'utils/validation'

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InputWrapper = styled('div')`
  display: flex;
  gap: 20px;
`

const SignUpForm = () => {
  return (
    <FormWrapper>
      <InputWrapper>
        <Input
          $width='328px'
          $height='56px'
          $rowHeight='86px'
          name='firstName'
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='20px'
          $color={black}
          label='First name'
        />
        <Input
          $width='328px'
          $height='56px'
          $rowHeight='86px'
          name='lastName'
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='20px'
          $color={black}
          label='Last name'
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type='phone'
          $allowedSymbols={allowNumbersOnlyRegExp}
          label='Phone'
          $width='328px'
          $height='56px'
          $rowHeight='86px'
          $addonsHeight='56px'
          name='phone'
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='20px'
          $color={black}
        />
        <Input
          label='Email'
          name='email'
          $width='328px'
          $height='56px'
          $rowHeight='86px'
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='20px'
          $color={black}
        />
      </InputWrapper>

      <Input
        $width='676px'
        $height='56px'
        $rowHeight='86px'
        name='businessName'
        $fontSize='16px'
        $fontWeight='400'
        $lineHeight='20px'
        $color={black}
        label='Business name'
      />

      <Input
        $width='676px'
        $height='56px'
        $rowHeight='86px'
        name='password'
        type='password'
        $fontSize='16px'
        $fontWeight='400'
        $lineHeight='20px'
        $color={black}
        label='Password'
      />
    </FormWrapper>
  )
}

export default SignUpForm
