import Button from 'components/Button/Button'
import TypographyText from 'components/TypographyText/TypographyText'
import { black } from 'constants/theme'
import React from 'react'
import styled from 'styled-components'
import { IEmptyState } from './types'

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 400px);
  gap: 24px;
`

const EmptyState = ({ title, buttonText, onClick, buttonWidth, src }: IEmptyState) => {
  return (
    <Wrapper>
      <img src={src} alt='No data' width={250} height={200} />
      <TypographyText $fontSize='24px' $lineHeight='28px' color={black}>
        {title.toUpperCase()}
      </TypographyText>
      <Button
        $fontSize='20px'
        $height='48px'
        $width={buttonWidth}
        $lineHeight='24px'
        $fontWeight='700'
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Wrapper>
  )
}

export default EmptyState
