import { USA_COUNTRY_NUMBER } from 'constants/constants'

export const getCountryCodeAndPhoneNumber = (phoneNumber: string) => {
  const phoneDigits = 10
  const countryCodeLength = phoneNumber.length - phoneDigits

  return {
    countryCode: phoneNumber.substr(0, countryCodeLength),
    phone: phoneNumber.substr(countryCodeLength, phoneNumber.length),
  }
}
export const formatNumberWithPhoneMask = (value: string, allowedDialpadSymbols: RegExp) =>
  value
    ?.replace(allowedDialpadSymbols, '') // remove everything except digits
    // limit input to 10 digits
    .replace(/(.{10}).*$/, '$1')
    // insert space between groups of digits
    .replace(/^(.?.?.?)(.?.?.?)(.?.?.?.?)$/, '$1 $2 $3')

export const unformatPhoneNumber = (phoneNumber: string, withCountryCode = true) => {
  if (phoneNumber) {
    const unformattedPhone = phoneNumber.split('-').join('')
    return withCountryCode ? USA_COUNTRY_NUMBER + unformattedPhone : unformattedPhone
  }

  return ''
}

export const capitalizeText = (str: string) => {
  return `${str?.charAt(0).toUpperCase()}${str?.slice(1, str.length).toLowerCase()}`
}

export const formatPrice = (price: number, minimumFractionDigits = 0) => {
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
  })
}

export const handleNumericInputKeyDown = (event: any) => {
  const value = event.target?.value || ''
  if ((event.key === '0' && !value) || ['-', '.', 'e', '+', ' ', ','].includes(event.key)) {
    event.preventDefault()
  }
}
