import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'
import TypographyText from 'components/TypographyText/TypographyText'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import CartApi from 'modules/Cart/services/CartApi'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import ProductsApi from '../services/ProductsApi'
import { cartActions } from 'store/reducers/Cart/CartSlice'
import { openInfoMessage } from 'components/Notification/Notification'

const useDeleteProduct = ({ productId, clearFields }: { productId: string; clearFields: () => void }) => {
  const [isRemoveProductsModalOpen, setIsRemoveProductsModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const { isAuthenticated } = useAppSelector((state) => state.authReducer)
  const openRemoveProductsModal = () => setIsRemoveProductsModalOpen(true)
  const closeRemoveProductsModal = () => setIsRemoveProductsModalOpen(false)

  const dispatch = useAppDispatch()

  const turnOnEditMode = () => setIsEditMode(true)
  const turnOffEditMode = () => setIsEditMode(false)

  const removeProducts = useCallback(() => {
    if (isAuthenticated) {
      dispatch(CartApi.addOffersToCart({ offers: [], productId }))
        .unwrap()
        .then(() => {
          openInfoMessage({ content: 'Product was deleted from Offers' })
          turnOffEditMode()
          clearFields()
          closeRemoveProductsModal()
        })
    } else {
      dispatch(ProductsApi.convertProductsToCart([]))
        .unwrap()
        .then(() => {
          turnOffEditMode()
          openInfoMessage({ content: 'Product was deleted from Offers' })
          clearFields()
          dispatch(cartActions.deleteProductInCart({ deletedProductId: productId }))
          closeRemoveProductsModal()
        })
    }
  }, [clearFields, dispatch, productId, isAuthenticated])

  const DeleteProductsModal = useMemo(() => {
    return (
      <Modal open={isRemoveProductsModalOpen} onCancel={closeRemoveProductsModal} width={350} hideFooter hideHeader>
        <ModalTextWrapper>
          <TypographyText $fontSize='20px' $lineHeight='24px' $fontWeight='600'>
            Remove all items
          </TypographyText>
          <TypographyText $fontSize='16px' $lineHeight='20px'>
            Are you sure about removing all items?
          </TypographyText>
        </ModalTextWrapper>
        <ButtonWrapper>
          <Button
            variant='outlined'
            $fontSize={'14px'}
            $width={'78px'}
            $height={'32px'}
            $margin={'0 8px 0 0'}
            onClick={closeRemoveProductsModal}
          >
            Cancel
          </Button>
          <Button $fontSize={'14px'} $width={'86px'} $height={'32px'} onClick={removeProducts}>
            Remove
          </Button>
        </ButtonWrapper>
      </Modal>
    )
  }, [isRemoveProductsModalOpen, removeProducts])

  return { openRemoveProductsModal, DeleteProductsModal, isEditMode, turnOnEditMode, turnOffEditMode }
}

export default useDeleteProduct

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`
const ModalTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
