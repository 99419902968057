import { useState } from 'react'

import { black } from 'constants/theme'
import { InputNativeOnChangeProp, InputProps } from 'components/Input/types'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import CustomInput from './CustomInput'
import Icon from 'components/Icon/Icon'

const PasswordInput = ({
  type,
  value,
  onChange,
  disabled,
  name,
  placeholder,
  onBlur,
  ...rest
}: InputProps & InputNativeOnChangeProp) => {
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => setShowPassword(!showPassword)
  return (
    <CustomInput
      role='input'
      name={name}
      type={showPassword ? 'text' : type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      placeholder={placeholder}
      endAdornment={
        <Icon
          Component={showPassword ? EyeOutlined : EyeInvisibleOutlined}
          onClick={togglePasswordVisibility}
          $color={black}
        />
      }
      {...rest}
      {...rest}
    />
  )
}

export default PasswordInput
