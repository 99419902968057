import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { alabaster } from '../../constants/theme'
import Header from './Header'
import { useAppSelector } from 'hooks/useStore'
import { getLoading } from 'store/selectors/common'
import Spinner from 'components/Spinner/Spinner'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'

const StyledLayout = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${alabaster};
`
const StyledOutlet = styled('div')`
  flex-grow: 1;
  width: 100%;
`

const Layout = () => {
  const store = useAppSelector((state) => state)
  const loadingState = getLoading(store)

  return (
    <StyledLayout>
      <Header />
      <StyledOutlet>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </StyledOutlet>
      <Spinner loading={loadingState} />
    </StyledLayout>
  )
}

export default Layout
