import { Collapse as AntCollapse, CollapseProps } from 'antd'
import { ICssProperty } from 'interfaces/ICssProperty'
import styled from 'styled-components'

const StyledCollapse = styled(AntCollapse)<CollapseProps & ICssProperty>`
  border: none;
  background: transparent;
  border-radius: ${(props) => props.$borderRadius || '12px'};
  &.ant-collapse .ant-collapse-item:last-child {
    border-bottom: ${(props) => props.$borderBottom || ''};
    border-radius: ${(props) => props.$borderRadius || '12px'};
  }
  .ant-collapse-item {
    background: white;
    border: none;
    ${(props) => props.$responsiveStyles}
    border-radius: ${(props) => props.$borderRadius || '12px'};
    border-bottom: ${(props) => props.$borderBottom || ''};
    margin-bottom: ${(props) => props.$marginBottom || '8px'};

    .ant-collapse-expand-icon {
      align-self: center;

      .anticon {
        font-size: ${(props) => props.$iconsSize || '16px'};
      }
    }

    .ant-collapse-content {
      border: none;
      border-radius: ${(props) => props.$contentBorderRadius || '0'};
      width: auto;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .ant-collapse-header {
      padding: 10px;
      padding: ${(props) => props.$padding || '12px 16px'};

      .ant-collapse-expand-icon {
        display: ${(props) => (props.collapsible === 'icon' || props.$hideIcon ? 'none' : '')};
      }
    }

    margin: ${(props) => props.$margin || '0 0 15px 0'};
    width: ${(props) => props.$width};
  }
  .ant-collapse-item-active {
    .dfIkJp {
      -webkit-line-clamp: 10;
    }
  }
`

const Collapse = ({
  items,
  expandIcon,
  expandIconPosition,
  collapsible,
  activeKey,
  onChange,
  $iconsSize,
  $borderRadius,
  $borderBottom,
  $padding,
  $margin,
  $hideIcon,
  $contentBorderRadius,
  ...props
}: CollapseProps & ICssProperty) => {
  return (
    <StyledCollapse
      items={items}
      expandIcon={expandIcon}
      expandIconPosition={expandIconPosition}
      collapsible={collapsible}
      activeKey={activeKey}
      onChange={onChange}
      $iconsSize={$iconsSize}
      $borderRadius={$borderRadius}
      $borderBottom={$borderBottom}
      $padding={$padding}
      $margin={$margin}
      $hideIcon={$hideIcon}
      $contentBorderRadius={$contentBorderRadius}
      {...props}
    />
  )
}

export default Collapse
