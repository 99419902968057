import { URLS } from 'constants/urls'
import CartProductLabel from 'modules/Cart/components/CartProductLabel'
import CartProductTable from 'modules/Cart/components/CartProductTable'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import CartApi from '../services/CartApi'
import { groupBy } from 'lodash'
import Modal from 'components/Modal/Modal'
import styled from 'styled-components'
import TypographyText from 'components/TypographyText/TypographyText'
import Button from 'components/Button/Button'

const useCart = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  const { data } = useAppSelector((state) => state.cartReducer)
  const { isAuthenticated } = useAppSelector((state) => state.authReducer)
  const [isFinalizeOffer, setIsFinalizeOffer] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const navigateToHome = useCallback(() => navigate(URLS.DASHBOARD.PRODUCTS), [navigate])
  const navigateToLogin = useCallback(() => navigate(URLS.AUTH.LOGIN), [navigate])
  const navigateToSignup = useCallback(() => navigate(URLS.AUTH.CREATE_ACCOUNT), [navigate])

  const openAuthModal = () => setIsAuthModalOpen(true)
  const closeAuthModal = useCallback(() => setIsAuthModalOpen(false), [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    isAuthenticated && dispatch(CartApi.getCart())
  }, [dispatch, isAuthenticated])

  const handleConfirmOffer = () => {
    if (isAuthenticated) {
      setIsFinalizeOffer(true)
    } else {
      openAuthModal()
    }
  }

  const offers = useMemo(() => {
    return data
      .map((cart) => {
        const groupedProducts = groupBy(cart.products, 'productId')
        return Object.entries(groupedProducts).map(([productId, products], index) => {
          return {
            key: `${productId}-${index}`,
            label: <CartProductLabel product={products[0]} />,
            children: <CartProductTable products={products} />,
          }
        })
      })
      .flat()
  }, [data])

  const AuthModal = useMemo(() => {
    return (
      <Modal open={isAuthModalOpen} onCancel={closeAuthModal} width={750} hideFooter hideHeader centered>
        <TypographyText $fontSize='32px' $lineHeight='36px' $fontWeight='700' $textAlign='center' $display='flex'>
          To confirm the offer, you need to log in to your account or create an account
        </TypographyText>
        <ButtonWrapper>
          <Button
            variant='outlined'
            $fontSize={'20px'}
            $fontWeight='700'
            $lineHeight='24px'
            $width={'339px'}
            $height={'48px'}
            $margin={'0 20px 0 0'}
            onClick={navigateToSignup}
          >
            Create an account
          </Button>
          <Button
            $fontSize={'20px'}
            $fontWeight='700'
            $lineHeight='24px'
            $width={'339px'}
            $height={'48px'}
            onClick={navigateToLogin}
          >
            Log in
          </Button>
        </ButtonWrapper>
      </Modal>
    )
  }, [isAuthModalOpen, navigateToLogin, navigateToSignup, closeAuthModal])

  return { navigateToHome, offers, handleConfirmOffer, AuthModal, isFinalizeOffer }
}

export default useCart

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 44px;
`
