class LocalStorage {
  static getItem(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key) || '')
    } catch (error) {
      return ''
    }
  }

  static setItem(key: string, value: unknown) {
    try {
      localStorage.setItem(key, JSON.stringify(value))
      return true
    } catch (error) {
      return ''
    }
  }

  static removeItem(key: string) {
    try {
      return localStorage.removeItem(key)
    } catch (error) {
      return ''
    }
  }
}

export default LocalStorage
