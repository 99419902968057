import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import LocalStorage from 'utils/LocalStorage'
import { authReducerName } from './action-types'
import { IUser, UserRole } from 'interfaces/IUser'
import AuthorizationApi from 'modules/Auth/services/AuthorizationApi'

export interface AuthState {
  user: IUser | null
  role: UserRole | null
  isAuthenticated: boolean
  loading: boolean
}

export const initialState: AuthState = {
  user: LocalStorage.getItem('user') || null,
  role: null,
  isAuthenticated: LocalStorage.getItem('isAuthenticated'),
  loading: false,
}

export const authSlice = createSlice({
  name: authReducerName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AuthorizationApi.login.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(AuthorizationApi.login.fulfilled.type, (state, { payload }: PayloadAction<IUser>) => {
      const { role } = payload

      state.user = payload
      state.role = role

      state.isAuthenticated = true
      state.loading = false
    })
    builder.addCase(AuthorizationApi.login.rejected.type, (state) => {
      state.isAuthenticated = false
      state.loading = false
    })

    builder.addCase(AuthorizationApi.logout.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(AuthorizationApi.logout.fulfilled.type, (state) => {
      state.loading = false

      state.isAuthenticated = false
      state.user = null
      state.role = null
      state.loading = false
    })
    builder.addCase(AuthorizationApi.logout.rejected.type, (state) => {
      state.isAuthenticated = false
      state.user = null
      state.role = null
      state.loading = false
    })

    builder.addCase(AuthorizationApi.signUp.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(AuthorizationApi.signUp.fulfilled.type, (state, { payload }: PayloadAction<IUser>) => {
      const { role } = payload

      state.user = payload
      state.role = role
      state.isAuthenticated = true
      state.loading = false
    })
    builder.addCase(AuthorizationApi.signUp.rejected.type, (state) => {
      state.isAuthenticated = false
      state.loading = false
    })

    builder.addCase(AuthorizationApi.resetPassword.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(AuthorizationApi.resetPassword.fulfilled.type, (state) => {
      state.loading = false
    })
    builder.addCase(AuthorizationApi.resetPassword.rejected.type, (state) => {
      state.loading = false
    })

    builder.addCase(AuthorizationApi.createNewPassword.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(AuthorizationApi.createNewPassword.fulfilled.type, (state) => {
      state.loading = false
    })
    builder.addCase(AuthorizationApi.createNewPassword.rejected.type, (state) => {
      state.loading = false
    })
    builder.addCase(AuthorizationApi.changePassword.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(AuthorizationApi.changePassword.fulfilled.type, (state) => {
      state.isAuthenticated = false
      state.user = null
      state.role = null
      state.loading = false
    })
    builder.addCase(AuthorizationApi.changePassword.rejected.type, (state) => {
      state.loading = false
    })
  },
})
export const authActions = authSlice.actions

export default authSlice.reducer
