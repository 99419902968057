const COLORS = {
  abbey: '#EAEAEA',
  acadia: '#262626',
  acapulco: '#D7D7D7',
  black: '#000000',
  white: '#FFFFFF',
  aluminium: '#A9ABB4',
  mercury: '#E6E6E6',
  errorRed: '#f5222d',
  scorpion: '#606060',
  gallery: '#EFEFEF',
  silver: '#CBCBCB',
  codGray: '#171717',
  alabaster: '#F9F9F9',
  glacier: '#749DC8',
  dodgerBlue: '#40A9FF',
  tundora: '#434343',
  gold: '#FFD600',
  doveGray: '#676767',
  gray: '#818181',
  guardsmansRed: '#C60000',
  mineShaft: '#303030',
  alto: '#DFDFDF',
  silverChalise: '#ABABAB',
  emperor: '#515151',
  cadetBlue: '#B2BAC2',
  mischka: '#d0d5dd',
  ottoman: '#E6F7ED',
  goblin: '#398647',
  provincialPink: '#FEF3F2',
  darkGray: '#6A6A6A',
  vesuvius: '#B14508',
  tahitiGold: '#F18D09',
  rumSwizzle: '#F9F4E5',
  grayChalise: '#898989',
  wildSand: '#f5f5f5',
}

module.exports = COLORS
