import { DragAndDropIcon } from 'assets/DragAndDropIcon'
import Button from 'components/Button/Button'
import Divider from 'components/Divider/Divider'
import Icon from 'components/Icon/Icon'
import Modal from 'components/Modal/Modal'
import { IUploadModalProps } from 'components/Modal/types'
import TypographyText from 'components/TypographyText/TypographyText'
import styled from 'styled-components'
import UploadedFile from './components/UploadedFile'
import { useUploadFile } from 'hooks/useUploadFile'
import Dragger from 'components/Dragger/Dragger'
import { gray, silver, white } from 'constants/theme'
import { FileIcon } from 'assets/FileIcon'
import CustomRadio from 'components/Radio/CustomRadio'
import { EDeliveryMethod } from 'enums/EDeliveryMethod'
import CustomCheckbox from 'components/Checkbox/components/CustomCheckbox'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { ChangeEvent, useState } from 'react'

const DraggerContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 118px;
  justify-content: center;
`

const ActionsContainer = styled('div')`
  display: flex;
  height: 48px;
  justify-content: end;
  margin-top: 20px;
`

const ContentContainer = styled('div')`
  padding: 20px 0 10px;
`

const Title = styled('div')`
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;
  margin-bottom: 30px;
`

const DeliveryOptions = styled('div')`
  display: flex;
  gap: 20px;
  margin: 20px 0;
`
const TakeAllWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`
const AgreedWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
`
const DeliveryOption = styled('div')`
  background: ${white};
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${silver};
  width: 340px;
  height: 40px;
  align-items: center;
`

const UploadInventoryModal = (props: IUploadModalProps) => {
  const [deliveryMethod, setDeliveryMethod] = useState<EDeliveryMethod>()
  const [takeAll, setTakeAll] = useState(false)
  const [agreed, setAgreed] = useState(false)

  const handleShipping = (e: ChangeEvent<HTMLInputElement>) => {
    setDeliveryMethod(e.target.value as EDeliveryMethod)
  }
  const handleTakeAllCheckbox = (e: CheckboxChangeEvent) => {
    setTakeAll(e.target.checked)
  }

  const { beforeUpload, accept, file, percent, status, deleteFile, uploadFile, downloadTemplate } = useUploadFile(
    props.open,
    props.onOk,
    deliveryMethod,
    takeAll,
  )

  const handleAgreedCheckbox = (e: CheckboxChangeEvent) => {
    setAgreed(e.target.checked)
  }

  return (
    <Modal
      open={props.open}
      width={props.width}
      onOk={props.onOk}
      onCancel={props.onCancel}
      hideHeader={props.hideHeader}
      hideFooter={props.hideFooter}
      bodyPadding='24px'
    >
      <Title>Upload file</Title>
      {!file ? (
        <Dragger name='inventory' multiple={false} accept={accept} beforeUpload={beforeUpload}>
          <DraggerContentWrapper>
            <Icon Component={DragAndDropIcon} $width='24px' $height='24px' />
            <TypographyText $fontSize='16px' $lineHeight='20px' $color={'black'} $margin='16px 0 4px 0'>
              Click or drag a logo file to this area to upload
            </TypographyText>
          </DraggerContentWrapper>
        </Dragger>
      ) : (
        <UploadedFile file={file} percent={percent} status={status} deleteFile={deleteFile} tryAgain={uploadFile} />
      )}
      <ContentContainer>
        <TypographyText $fontSize='16px' $lineHeight='20px' $color={gray}>
          Formats accepted are .csv and .xlsx
        </TypographyText>
        <Divider $margin='17px 0' />
        <TypographyText $fontSize='16px' $lineHeight='20px'>
          If you do not have a file you can use the sample below:
        </TypographyText>
        <Button
          variant='outlined'
          $height='40px'
          $width='270px'
          $fontSize='16px'
          $margin='20px 0 0'
          onClick={downloadTemplate}
        >
          <Icon Component={FileIcon} />
          Download Sample Template
        </Button>
      </ContentContainer>
      {file && (
        <>
          <Divider $margin='20px 0' />
          <TypographyText $fontSize='18px' $lineHeight='20px' $fontWeight='500'>
            Choose a delivery method
          </TypographyText>
          <DeliveryOptions>
            <DeliveryOption>
              <CustomRadio
                checked={deliveryMethod === EDeliveryMethod.SHIPPING}
                onChange={handleShipping}
                value={EDeliveryMethod.SHIPPING}
              />

              <TypographyText $fontSize='16px' $lineHeight='20px'>
                Shipping
              </TypographyText>
            </DeliveryOption>
            <DeliveryOption>
              <CustomRadio
                checked={deliveryMethod === EDeliveryMethod.DROPOFF}
                value={EDeliveryMethod.DROPOFF}
                onChange={handleShipping}
              />

              <TypographyText $fontSize='16px' $lineHeight='20px'>
                Drop off (Secaucus, NJ 07940)
              </TypographyText>
            </DeliveryOption>
          </DeliveryOptions>
          <TakeAllWrapper>
            <CustomCheckbox name='takeAll' onChange={handleTakeAllCheckbox} checked={takeAll} />
            <TypographyText $fontSize='16px' $lineHeight='20px'>
              This is a `take all` offer
            </TypographyText>
          </TakeAllWrapper>
          <AgreedWrapper>
            <CustomCheckbox name='agree' onChange={handleAgreedCheckbox} checked={agreed} />
            <TypographyText $fontSize='16px' $lineHeight='20px'>
              I have read and agree to the <a href='#'>Seller Guidelines</a> (required).
            </TypographyText>
          </AgreedWrapper>
        </>
      )}
      <ActionsContainer>
        <Button
          variant='outlined'
          $width='138px'
          $margin='0 20px 0'
          $fontSize='20px'
          $fontWeight='700'
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <Button
          $width='138px'
          $fontSize='20px'
          $fontWeight='700'
          disabled={!file || !deliveryMethod || !agreed}
          onClick={uploadFile}
        >
          Send Offer
        </Button>
      </ActionsContainer>
    </Modal>
  )
}

export default UploadInventoryModal
