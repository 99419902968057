export const Delete = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9 1.66663C12.2498 1.66672 12.5907 1.77686 12.8744 1.98147C13.1581 2.18607 13.3703 2.47475 13.4808 2.80663L13.9333 4.16663H16.6667C16.8877 4.16663 17.0996 4.25442 17.2559 4.4107C17.4122 4.56698 17.5 4.77895 17.5 4.99996C17.5 5.22097 17.4122 5.43293 17.2559 5.58922C17.0996 5.7455 16.8877 5.83329 16.6667 5.83329L16.6642 5.89246L15.9417 16.0116C15.8966 16.6421 15.6143 17.2322 15.1517 17.6629C14.6891 18.0937 14.0805 18.3332 13.4483 18.3333H6.55167C5.91955 18.3332 5.31092 18.0937 4.84831 17.6629C4.38569 17.2322 4.10342 16.6421 4.05833 16.0116L3.33583 5.89163C3.33433 5.87222 3.33349 5.85276 3.33333 5.83329C3.11232 5.83329 2.90036 5.7455 2.74408 5.58922C2.5878 5.43293 2.5 5.22097 2.5 4.99996C2.5 4.77895 2.5878 4.56698 2.74408 4.4107C2.90036 4.25442 3.11232 4.16663 3.33333 4.16663H6.06667L6.51917 2.80663C6.62975 2.47462 6.84203 2.18584 7.12592 1.98122C7.4098 1.77661 7.75089 1.66654 8.10083 1.66663H11.9ZM7.5 8.33329C7.29589 8.33332 7.09889 8.40826 6.94636 8.54389C6.79383 8.67952 6.69638 8.86642 6.6725 9.06913L6.66667 9.16663V14.1666C6.6669 14.379 6.74823 14.5833 6.89404 14.7378C7.03985 14.8922 7.23913 14.9852 7.45116 14.9976C7.6632 15.0101 7.87198 14.9411 8.03486 14.8047C8.19774 14.6684 8.30241 14.475 8.3275 14.2641L8.33333 14.1666V9.16663C8.33333 8.94561 8.24554 8.73365 8.08926 8.57737C7.93297 8.42109 7.72101 8.33329 7.5 8.33329ZM12.5 8.33329C12.279 8.33329 12.067 8.42109 11.9107 8.57737C11.7545 8.73365 11.6667 8.94561 11.6667 9.16663V14.1666C11.6667 14.3876 11.7545 14.5996 11.9107 14.7559C12.067 14.9122 12.279 15 12.5 15C12.721 15 12.933 14.9122 13.0893 14.7559C13.2455 14.5996 13.3333 14.3876 13.3333 14.1666V9.16663C13.3333 8.94561 13.2455 8.73365 13.0893 8.57737C12.933 8.42109 12.721 8.33329 12.5 8.33329ZM11.9 3.33329H8.1L7.8225 4.16663H12.1775L11.9 3.33329Z'
        fill='black'
      />
    </svg>
  )
}
