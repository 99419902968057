export const File = ({ fill }: { fill: string }) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5865 7.15496C16.547 7.06452 16.4917 6.98183 16.4232 6.91079L11.4232 1.91079C11.3521 1.84228 11.2694 1.78697 11.179 1.74746C11.154 1.73579 11.1273 1.72913 11.1007 1.71996C11.0309 1.69623 10.9583 1.68194 10.8848 1.67746C10.8673 1.67579 10.8515 1.66663 10.834 1.66663H5.00065C4.08148 1.66663 3.33398 2.41413 3.33398 3.33329V16.6666C3.33398 17.5858 4.08148 18.3333 5.00065 18.3333H15.0007C15.9198 18.3333 16.6673 17.5858 16.6673 16.6666V7.49996C16.6673 7.48246 16.6582 7.46663 16.6565 7.44829C16.6524 7.37472 16.6381 7.30208 16.614 7.23246C16.6057 7.20579 16.5982 7.17996 16.5865 7.15496ZM13.8223 6.66663H11.6673V4.51163L13.8223 6.66663ZM5.00065 16.6666V3.33329H10.0007V7.49996C10.0007 7.72097 10.0884 7.93293 10.2447 8.08922C10.401 8.2455 10.613 8.33329 10.834 8.33329H15.0007L15.0023 16.6666H5.00065Z'
        fill={fill}
      />
      <path
        d='M6.66602 9.99996H13.3327V11.6666H6.66602V9.99996ZM6.66602 13.3333H13.3327V15H6.66602V13.3333ZM6.66602 6.66663H8.33268V8.33329H6.66602V6.66663Z'
        fill={fill}
      />
    </svg>
  )
}
