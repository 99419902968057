/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from 'components/Button/Button'
import Table from 'components/Table/Table'
import { FormikProvider, useFormik } from 'formik'
import { memo } from 'react'
import styled from 'styled-components'
import { renderColumns } from '../utils/renderColumns'
import EditIcon from 'assets/EditIcon'
import { black, white } from 'constants/theme'
import DeleteIcon from 'assets/DeleteIcon'
import { ICartProduct } from 'interfaces/ICartProduct'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import CartApi from '../services/CartApi'
import { IUpdateCartProductPayload } from '../types'
import useDeleteCart from '../utils/useDeleteCart'
import Icon from 'components/Icon/Icon'
import { CheckOutlined } from '@ant-design/icons'
import { cartActions } from 'store/reducers/Cart/CartSlice'
import ProductsApi from 'modules/Products/services/ProductsApi'

const CartProductTable = ({ products: offers }: { products: ICartProduct[] }) => {
  const dispatch = useAppDispatch()
  const productId = offers.find((product) => product.productId)?.productId
  const { isAuthenticated } = useAppSelector((state) => state.authReducer)
  const initialValues: { offers: IUpdateCartProductPayload[]; productId: string; totalPrice: number } = {
    offers: offers.map((item: ICartProduct) => {
      return {
        quantity: item.quantity,
        size: item.size,
        offerProductId: item.id,
        price: item.price,
        productId: item.productId,
      }
    }),
    productId: productId || '',
    totalPrice: 0,
  }

  const { openDeleteCartModal, DeleteCartModal, isEditMode, turnOnEditMode, turnOffEditMode } = useDeleteCart(productId)

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const offers = values.offers.filter((offer) => offer.quantity && offer.price)
      if (isAuthenticated) {
        dispatch(CartApi.addOffersToCart({ offers, productId: values.productId }))
          .unwrap()
          .then(() => turnOffEditMode())
      } else {
        console.log(offers)
        dispatch(ProductsApi.convertProductsToCart(offers))
          .unwrap()
          .then((payload) => {
            turnOffEditMode()
            dispatch(cartActions.editProductsInCart(payload))
          })
      }
    },
  })

  const { values } = formik

  const totalCartPrice = offers.reduce((acc, cartProduct) => {
    return acc + cartProduct.quantity * cartProduct.price
  }, 0)

  const disableSaveEditCartButton =
    totalCartPrice === values.totalPrice ||
    values.offers.some((offer) => (offer.quantity && !offer.price) || (!offer.quantity && offer.price))

  return (
    <FormikProvider value={formik}>
      <TableWrapper>
        {!isEditMode ? (
          <Button $alignSelf='flex-end' $margin='0 20px 0 0' $width={'96px'} $height='40px' onClick={turnOnEditMode}>
            <EditIcon fill={white} />
            Edit
          </Button>
        ) : (
          <ButtonWrapper>
            <Button $width={'96px'} $height='40px' onClick={formik.handleSubmit} disabled={disableSaveEditCartButton}>
              <Icon Component={CheckOutlined} $color={disableSaveEditCartButton ? black : white} $fontSize='14px' />
              Save
            </Button>
            <Button $width={'200px'} $height='40px' variant='outlined' onClick={openDeleteCartModal}>
              <DeleteIcon width='20px' height='20px' fill={black} />
              Delete all items
            </Button>
          </ButtonWrapper>
        )}
        <FormikProvider value={formik}>
          <Table
            columns={renderColumns({ offers: values.offers, disabled: !isEditMode })}
            className='offers'
            dataSource={offers}
            scroll={{ x: '100%' }}
            rowKey={'size'}
            pagination={false}
          />
        </FormikProvider>
      </TableWrapper>
      {DeleteCartModal}
    </FormikProvider>
  )
}

export default memo(CartProductTable)

const TableWrapper = styled('div')`
  max-width: calc(100vw - 30px);
  overflow: auto;
  display: flex;
  flex-direction: column;

  .offers {
    padding: 0;
    margin: 20px 0;
  }
`
const ButtonWrapper = styled('div')`
  display: flex;
  gap: 10px;
  align-self: flex-end;
  margin: 0 10px 0 0;
`
