import Logo from '../../assets/Logo'
import styled from 'styled-components'
import { mercury, white } from 'constants/theme'
import { useLocation, useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { useCallback, useEffect, useState } from 'react'
import MyProfileIcon from 'assets/MyProfile'
import Icon from 'components/Icon/Icon'
import OfferIcon from 'assets/OfferIcon'
import { useAppSelector } from 'hooks/useStore'
import TypographyText from 'components/TypographyText/TypographyText'
import { formatPrice } from 'utils/helpers'

const Wrapper = styled('div')`
  padding: 0 40px;
  height: 72px;
  align-content: center;
  background: ${mercury};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
`
const LogoWrapper = styled('div')`
  cursor: pointer;
  margin-right: 80px;
`

const Icons = styled('div')`
  display: flex;
  gap: 20px;
  margin-left: auto;
  align-items: center;
`
const IconWrapper = styled('div')`
  background: ${white};
  padding: 9px 10px;
  border-radius: 4px;
`
const OfferIconWrapper = styled('div')`
  border-radius: 4px;
  background: ${white};
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 10px;
  justify-content: center;
`
const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showProfile, setShowProfile] = useState<boolean>(true)
  useEffect(() => {
    if (location.pathname.includes('login') || location.pathname.includes('create-account')) {
      setShowProfile(false)
    } else {
      setShowProfile(true)
    }
  }, [location])

  const { totalQty, totalPrice } = useAppSelector((state) => state.cartReducer)

  const navigateToHome = useCallback(() => navigate(URLS.ROOT), [navigate])
  const navigateToProfile = useCallback(() => navigate(URLS.DASHBOARD.PROFILE), [navigate])
  const navigateToCart = useCallback(() => navigate(URLS.DASHBOARD.CART), [navigate])

  return (
    <Wrapper>
      <LogoWrapper onClick={navigateToHome}>
        <Logo />
      </LogoWrapper>
      <Icons>
        {showProfile && (
          <IconWrapper onClick={navigateToProfile}>
            <Icon Component={MyProfileIcon} />
          </IconWrapper>
        )}
        <OfferIconWrapper onClick={navigateToCart}>
          <OfferIcon width='32' height='32' />
          {totalQty && totalQty > 0 ? (
            <TypographyText $fontSize='18px' $lineHeight='22px' $fontWeight='500' $margin='0 0 0 10px'>
              Items: {totalQty} ({formatPrice(totalPrice)})
            </TypographyText>
          ) : null}
        </OfferIconWrapper>
      </Icons>
    </Wrapper>
  )
}

export default Header
