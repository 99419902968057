export const NOTIFICATION_DURATION = 4

export const USA_COUNTRY_NUMBER = '+1'

export const DEFAULT_DATETIME_FORMAT = 'MM/dd/yy'

export const ALLOWED_FILE_TYPES = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

export const getReadableFileSizeString = (fileSizeInBytes: number) => {
  let i = -1
  const byteUnits = [' KB', ' MB', ' GB']
  do {
    fileSizeInBytes /= 1024
    i++
  } while (fileSizeInBytes > 1024)

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}
export const CART_CHECKOUT_INFO = [
  'Your offer will be manually reviewed by an OS Wholesale Account Executive',
  'Upon review, we will approve, modify or cancel your offer',
  'When approved, your Account Rep will facilitate payment and shipping terms directly with you.',
]
