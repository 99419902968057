import { alabaster, black, gallery, white } from 'constants/theme'
import styled from 'styled-components'
import { InputAdornment } from '@mui/material'
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons'
import CustomInput from 'components/Input/components/CustomInput'
import Button from 'components/Button/Button'
import { UploadInventory } from 'assets/UploadInventoryIcon'
import TypographyText from 'components/TypographyText/TypographyText'
import InfiniteScroll from 'react-infinite-scroll-component'
import Collapse from 'components/Collapse/Collapse'
import Icon from 'components/Icon/Icon'
import EmptyStateImage from 'assets/EmptyState.svg'
import { useProducts } from '../hooks/useProducts'
import { useAppSelector } from 'hooks/useStore'
import { useUploadFileModal } from '../hooks/useUploadFile'
import UploadInventoryModal from 'components/Modal/components/Logout/UploadInventory/UploadInventoryModal'
import { formatPrice } from 'utils/helpers'

const Header = styled('div')`
  background: ${white};
  padding: 28px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Content = styled('div')`
  padding: 20px 40px;
`

const EmptyStateContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const EmptyStateGrid = styled('div')`
  display: grid;
`

const ProductsAndCheckout = styled('div')<{ emptyState: boolean }>`
  display: flex;
  align-items: ${(props) => props.emptyState && 'center'};
  justify-content: ${(props) => (props.emptyState ? 'center' : 'space-between')};
  height: ${(props) => props.emptyState && 'calc(100vh - 250px)'};
`
const CheckoutWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid ${gallery};
  background: ${alabaster};
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  padding: 18px 12px;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 40px 40px 0;
`
const TotalWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
const Products = () => {
  const { searchTerm, handleSearch, products, fetchMoreData, loading, navigateToCart, resetSearch } = useProducts()
  const { totalQty, totalPrice } = useAppSelector((state) => state.cartReducer)
  const { handleUploadCancel, handleUploadOk, uploadModalState, openUploadInventoryModal } = useUploadFileModal()

  return (
    <>
      <Header>
        <CustomInput
          $height='56px'
          $width='676px'
          name='search'
          label='Search'
          value={searchTerm}
          onChange={handleSearch}
          endAdornment={searchTerm && <Icon Component={CloseOutlined} $color={black} onClick={resetSearch} />}
          startAdornment={
            <InputAdornment position='start'>
              <SearchOutlined />
            </InputAdornment>
          }
        />
        <Button $width='240px' $height='40px' onClick={openUploadInventoryModal}>
          <UploadInventory fill={white} /> Upload .xlsx or .csv file
        </Button>
      </Header>
      <ProductsAndCheckout emptyState={products.length <= 0}>
        <Content>
          <>
            {products.length > 0 ? (
              <InfiniteScroll
                dataLength={products.length}
                next={fetchMoreData}
                hasMore={true}
                loader={null}
                endMessage={null}
              >
                <Collapse
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <Icon Component={ArrowUpOutlined} $width='20px' $height='20px' />
                    ) : (
                      <Icon Component={ArrowDownOutlined} $width='20px' $height='20px' />
                    )
                  }
                  expandIconPosition='end'
                  items={products}
                  $contentBorderRadius='12px'
                  $width='calc(100vw - 400px)'
                />
              </InfiniteScroll>
            ) : (
              !loading && (
                <EmptyStateContainer>
                  <EmptyStateGrid>
                    <img src={EmptyStateImage} />
                    <TypographyText $transform='uppercase' $fontSize='24px' $fontWeight='400'>
                      No products found
                    </TypographyText>
                  </EmptyStateGrid>
                </EmptyStateContainer>
              )
            )}
          </>
        </Content>
        {totalQty && totalQty > 0 && products.length > 0 ? (
          <CheckoutWrapper>
            <TotalWrapper>
              <TypographyText $fontSize='18px' $lineHeight='22px' $fontWeight='500'>
                TOTAL ITEMS
              </TypographyText>
              <TypographyText $fontSize='20px' $lineHeight='24px' $fontWeight='700'>
                {totalQty}
              </TypographyText>
            </TotalWrapper>
            <TotalWrapper>
              <TypographyText $fontSize='18px' $lineHeight='22px' $fontWeight='500'>
                TOTAL PRICE
              </TypographyText>
              <TypographyText $fontSize='20px' $lineHeight='24px' $fontWeight='700'>
                {formatPrice(totalPrice)}
              </TypographyText>
            </TotalWrapper>
            <Button
              variant='outlined'
              $fontWeight='700'
              $fontSize='20px'
              $lineHeight='24px'
              $width='234px'
              $height='48px'
              onClick={navigateToCart}
              $cursor='pointer'
            >
              Finalize the offer
            </Button>
          </CheckoutWrapper>
        ) : null}
      </ProductsAndCheckout>
      <UploadInventoryModal
        onOk={handleUploadOk}
        onCancel={handleUploadCancel}
        open={uploadModalState}
        width={750}
        hideHeader
        hideFooter
      />
    </>
  )
}

export default Products
