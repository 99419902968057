import { InputNativeOnChangeProp, InputProps } from 'components/Input/types'
import { usePhoneMask } from './hooks/usePhoneMask'
import { USA_COUNTRY_NUMBER } from 'constants/constants'
import CustomInput from '../CustomInput'

const PhoneInput = ({
  $innerRef,
  name,
  onChange,
  value = '',
  $allowedSymbols,
  ...rest
}: InputProps & InputNativeOnChangeProp) => {
  const { handleChange, onPaste, onKeyDown } = usePhoneMask({
    value: value as string,
    $allowedSymbols,
    onChange,
  })
  return (
    <CustomInput
      addonBefore={USA_COUNTRY_NUMBER}
      type='tel'
      name={name}
      $innerRef={$innerRef}
      onChange={handleChange}
      onPaste={onPaste}
      onKeyDown={onKeyDown}
      startAdornment={'+1'}
      value={value}
      $padding='0px 18px 0px 6px'
      {...rest}
    />
  )
}

export default PhoneInput
