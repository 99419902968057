import { Button as AntdButton } from 'antd'
import styled, { css } from 'styled-components'
import { memo } from 'react'

import {
  white,
  black,
  scorpion,
  gallery,
  mineShaft,
  alto,
  codGray,
  silver,
  abbey,
  acadia,
  acapulco,
  gray,
  guardsmansRed,
} from 'constants/theme'
import { IButtonProps } from './types'

// Define variant styles
const variantStyles = {
  filled: css`
    background-color: ${black};
    color: ${white};
    border: none;
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      color: ${white};
      background-color: ${codGray};
      box-shadow:
        0px 1px 2px 0px rgba(0, 0, 0, 0.3),
        0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      border: none;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus {
      background-color: ${mineShaft};
      border: none;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
      background-color: ${black};
      border: none;
    }
    &.ant-btn-default:disabled {
      color: ${scorpion};
      background-color: ${gallery};
      border: none;
      cursor: not-allowed;
    }
  `,
  outlined: css`
    background-color: ${white};
    color: ${black};
    border: 1px solid ${scorpion};
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${abbey};
      color: ${black};
      border: 1px solid ${scorpion};
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus {
      background-color: ${alto};
      color: ${acadia};
      border: 1px solid ${acadia};
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
      background-color: ${acapulco};
      color: ${acadia};
      border: 1px solid ${scorpion};
    }
    &.ant-btn-default:disabled {
      background-color: ${white};
      color: ${scorpion};
      border: 1px solid ${silver};
      cursor: not-allowed;
    }
  `,
  text: css`
    background-color: white;
    color: black;
    border: none;
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${abbey};
      color: black;
      border: none;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus {
      background-color: ${alto};
      color: ${acadia};
      border: none;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
      background-color: ${acapulco};
      color: ${acadia};
      border: none;
    }
    &.ant-btn-default:disabled {
      background-color: ${white};
      color: ${scorpion};
      border: none;
      cursor: not-allowed;
    }
  `,
  elevated: css`
    background-color: white;
    color: black;
    box-shadow:
      0px 1px 2px 0px rgba(0, 0, 0, 0.3),
      0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border: none;
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${abbey};
      color: black;
      box-shadow:
        0px 1px 2px 0px rgba(0, 0, 0, 0.3),
        0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      border: none;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus {
      background-color: ${alto};
      color: ${acadia};
      box-shadow:
        0px 1px 2px 0px rgba(0, 0, 0, 0.3),
        0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      border: none;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
      background-color: ${acapulco};
      color: ${acadia};
      box-shadow:
        0px 1px 2px 0px rgba(0, 0, 0, 0.3),
        0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      border: none;
    }
    &.ant-btn-default:disabled {
      color: ${gray};
      border: none;
      background-color: ${gallery};
      cursor: not-allowed;
    }
  `,
  tonal: css`
    background-color: white;
    color: black;
    border: none;
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${abbey};
      color: black;
      box-shadow:
        0px 1px 2px 0px rgba(0, 0, 0, 0.3),
        0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      border: none;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus {
      background-color: ${alto};
      color: ${acadia};
      border: none;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
      background-color: ${acapulco};
      color: ${acadia};
      border: none;
    }
    &.ant-btn-default:disabled {
      color: ${gray};
      border: none;
      background-color: rgba(29, 27, 32, 0.12);
      cursor: not-allowed;
    }
  `,
  delete: css`
    background-color: white;
    color: ${guardsmansRed};
    border: 1px solid ${guardsmansRed};
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: ${white}80;
      color: ${guardsmansRed};
      border: 1px solid ${guardsmansRed};
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus {
      background-color: ${white};
      color: ${guardsmansRed};
      border: none;
    }
    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
      background-color: ${white};
      color: ${guardsmansRed};
      border: none;
    }
    &.ant-btn-default:disabled {
      color: ${gray};
      border: none;
      background-color: rgba(29, 27, 32, 0.12);
      cursor: not-allowed;
    }
  `,
}

const StyledButton = styled(AntdButton)<IButtonProps>`
  &.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => props.$height || '100%'};
    color: ${(props) => props.$color || white};
    width: ${(props) => props.$width || '100%'};
    min-width: ${(props) => props.$minWidth};
    padding: ${(props) => props.$padding || '8px 0'};
    margin: ${(props) => props.$margin};
    font-size: ${(props) => props.$fontSize || '16px'};
    line-height: ${(props) => props.$lineHeight || '20px'};
    font-weight: ${(props) => props.$fontWeight || 400};
    background: ${(props) => props.$background || black};
    border: ${(props) => props.$border || '1px solid'};
    border-color: ${(props) => props.$borderColor || 'transparent'};
    cursor: pointer;
    border-radius: ${(props) => props.$borderRadius || '6px'};
    box-shadow: ${(props) => props.$boxShadow};
    align-self: ${(props) => props.$alignSelf};
    ${(props) => props.variant && variantStyles[props.variant]}
    ${(props) => props.$responsiveStyles}
  }
  .ant-wave {
    box-shadow: none;
  }
`

const Button = ({ children, onClick, className, role, disabled, href, variant = 'filled', ...rest }: IButtonProps) => {
  return (
    <StyledButton
      onClick={onClick}
      className={className}
      role={role}
      href={href}
      disabled={disabled}
      variant={variant}
      {...rest}
    >
      {children}
    </StyledButton>
  )
}

export default memo(Button)
