import { ENDPOINTS } from 'constants/endpoints'
import httpClient from 'utils/HttpClient'
import { createAsyncThunk } from '@reduxjs/toolkit'

export default class FileUploaderApi {
  static uploadChunk = createAsyncThunk('upload-file', async (data: FormData) => {
    const response = await httpClient.request({
      url: ENDPOINTS.PRODUCTS.UPLOAD_FILE,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    })

    return response.data
  })
}
