import { URLS } from 'constants/urls'
import ForgotPassword from 'modules/Auth/pages/ForgotPassword/ForgotPassword'
import Login from 'modules/Auth/pages/Login/Login'
import SignUp from 'modules/Auth/pages/SignUp/SignUp'
import CreateNewPassword from 'modules/Auth/pages/CreateNewPassword/CreateNewPasssword'

const authRoutes = [
  {
    path: URLS.AUTH.LOGIN,
    Component: Login,
  },
  {
    path: URLS.AUTH.CREATE_ACCOUNT,
    Component: SignUp,
  },
  {
    path: URLS.AUTH.FORGOT_PASSWORD,
    Component: ForgotPassword,
  },
  {
    path: URLS.AUTH.SET_PASSWORD,
    Component: CreateNewPassword,
  },
]

export default authRoutes
