import { message } from 'antd'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios'
import { openErrorMessage } from 'components/Notification/Notification'
import AuthorizationApi from 'modules/Auth/services/AuthorizationApi'
import { store } from 'store'

interface IRequestConfig extends AxiosRequestConfig {
  shouldInterceptError?: boolean
}

class HttpClient {
  private baseInstance: AxiosInstance
  constructor() {
    this.baseInstance = axios.create()
    this.useInterceptors()
  }

  request(config: IRequestConfig) {
    const {
      shouldInterceptError = true,
      method,
      url,
      data,
      responseType,
      headers,
      params,
      timeout = 30000,
      signal,
    } = config
    return this.baseInstance({
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      ...(data && { data }),
      responseType,
      headers: {
        'Content-Type': 'application/json',
        'api-key': process.env.REACT_APP_SECRET_API_KEY,
        ...headers,
      },
      params,
      withCredentials: true,
      credentials: 'include',
      timeout,
      shouldInterceptError,
      signal,
    })
  }

  private useInterceptors() {
    this.baseInstance.interceptors.request.use(
      (config) => config,
      (error) => Promise.reject(error),
    )

    this.baseInstance.interceptors.response.use((response) => response, this.catchErrors)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private catchErrors(error: AxiosError | any) {
    if (error?.name === 'CanceledError') {
      return Promise.reject(error)
    }
    if (error.config.shouldInterceptError) {
      if (error?.response?.status === 401) {
        // session is expired
        if (store.getState().authReducer.isAuthenticated) {
          message.destroy()
          error?.response?.data?.message && openErrorMessage({ content: error?.response?.data?.message })
          store.dispatch(AuthorizationApi.logout())
          return Promise.reject(error)
        }
      }
      if (!axios.isCancel(error)) {
        message.destroy()
        openErrorMessage({ content: error?.response?.data?.message || error.message })
      }
    }

    return Promise.reject(error)
  }
}

const httpClient = new HttpClient()

export default httpClient
