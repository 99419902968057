import { Provider } from 'react-redux'
import { store } from 'store'
import { ConfigProvider } from 'antd'
import Routes from './routes/Routes'

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider theme={{ hashed: false }}>
        <Routes />
      </ConfigProvider>
    </Provider>
  )
}

export default App
