import Button from 'components/Button/Button'
import { FormikProps, FormikProvider } from 'formik'
import { black } from 'constants/theme'
import TypographyText from 'components/TypographyText/TypographyText'
import styled from 'styled-components'
import Input from 'components/Input/Input'
import { ICreateNewPasswordParams } from '../types'

const CreateNewPasswordForm = ({ formik }: { formik: FormikProps<ICreateNewPasswordParams> }) => {
  const { handleSubmit, isValid } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <TypographyText color={black} $fontSize='38px' $lineHeight='46px' $textAlign='center' $margin='0 0 44px 0'>
          Create a new password
        </TypographyText>
        <InputWrapper>
          <Input
            $width='676px'
            $height='56px'
            $rowHeight='84px'
            name='password'
            type='password'
            $fontSize='16px'
            $fontWeight='400'
            $lineHeight='20px'
            $color={black}
            $withLabel
            label='New password'
          />
          <Input
            $width='676px'
            $height='56px'
            $rowHeight='84px'
            name='confirmPassword'
            type='password'
            $fontSize='16px'
            $fontWeight='400'
            $lineHeight='20px'
            $color={black}
            $withLabel
            label='Confirm new password'
          />
        </InputWrapper>

        <Button
          $fontWeight='700'
          $fontSize='20px'
          $lineHeight='24px'
          $width='676px'
          $height='48px'
          onClick={handleSubmit}
          disabled={!isValid}
        >
          Create
        </Button>
      </Wrapper>
    </FormikProvider>
  )
}

export default CreateNewPasswordForm

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`
const InputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`
