import { useCallback, useEffect, useState } from 'react'
import { FormikHelpers, useFormik } from 'formik'
import { createNewPasswordSchema } from 'utils/validation'
import AuthorizationApi from 'modules/Auth/services/AuthorizationApi'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { ICreateNewPasswordParams } from '../types'

const useCreateNewPassword = () => {
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const [invitationLinkIsValid, setInvitationLinkIsValid] = useState<boolean | null>(null)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { loading } = useAppSelector((state) => state.authReducer)

  const formik = useFormik({
    initialValues: {
      token: '',
      password: '',
      confirmPassword: '',
    },
    validateOnMount: true,
    validationSchema: createNewPasswordSchema,
    onSubmit: async (values: ICreateNewPasswordParams, { resetForm }: FormikHelpers<ICreateNewPasswordParams>) => {
      await dispatch(AuthorizationApi.createNewPassword(values))
        .unwrap()
        .then(() => {
          setShowSuccessScreen(true)
          resetForm()
        })
        .catch((error) => error)
    },
  })
  const { setFieldValue, values } = formik

  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get('token') || ''
      setFieldValue('token', token)
    }
  }, [setFieldValue])

  useEffect(() => {
    values.token &&
      dispatch(AuthorizationApi.checkTokenExpiration(values.token))
        .unwrap()
        .then((isValid) => setInvitationLinkIsValid(isValid))
  }, [dispatch, values])

  const navigateToLogin = useCallback(() => navigate(URLS.AUTH.LOGIN), [navigate])
  const navigateToForgotPassword = useCallback(() => navigate(URLS.AUTH.FORGOT_PASSWORD), [navigate])

  return { showSuccessScreen, navigateToLogin, navigateToForgotPassword, formik, invitationLinkIsValid, loading }
}

export default useCreateNewPassword
