import { useCallback, useEffect, useMemo, useState } from 'react'
import ProductsApi from '../services/ProductsApi'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ProductLabel from '../components/ProductLabel'
import ProductTable from '../components/ProductTable'
import CartApi from 'modules/Cart/services/CartApi'
import { URLS } from 'constants/urls'

export const useProducts = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data, page, search, loading } = useAppSelector((state) => state.productsReducer)
  const { isAuthenticated } = useAppSelector((state) => state.authReducer)

  const [searchTerm, setSearchTerm] = useState('')
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null)

  const navigateToCart = useCallback(() => navigate(URLS.DASHBOARD.CART), [navigate])

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setSearchTerm(value)

      if (searchTimer) {
        clearTimeout(searchTimer)
      }

      const timer = setTimeout(() => {
        dispatch(ProductsApi.searchProducts({ search: value, page: 1 }))
        setSearchParams([['search', value]])
      }, 300)

      setSearchTimer(timer)
    },
    [dispatch, setSearchParams, searchTimer],
  )

  const resetSearch = useCallback(() => {
    setSearchTerm('')
    dispatch(ProductsApi.searchProducts({ search: '', page: 1 }))
    searchParams.delete('search')
    setSearchParams(searchParams)
  }, [dispatch, searchParams, setSearchParams])

  const fetchMoreData = () => {
    dispatch(ProductsApi.searchProducts({ search, page: page + 1 }))
  }

  const products = useMemo(() => {
    return data.map((product, index) => {
      return {
        key: `${product.id}-${index}`,
        label: <ProductLabel product={product} />,
        children: <ProductTable product={product} />,
      }
    })
  }, [data])

  useEffect(() => {
    const query = searchParams.get('search') || ''
    setSearchTerm(query)
    dispatch(ProductsApi.searchProducts({ search: query, page: 1 }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isAuthenticated && dispatch(CartApi.getCart())
  }, [dispatch, isAuthenticated])

  return { searchTerm, handleSearch, products, fetchMoreData, loading, navigateToCart, resetSearch }
}
