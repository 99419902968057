import TypographyText from 'components/TypographyText/TypographyText'
import styled from 'styled-components'
import Button from 'components/Button/Button'
import { black } from 'constants/theme'
import InvalidLink from 'assets/InvalidLink'

const InvalidLinkScreen = ({ navigateToForgotPassword }: { navigateToForgotPassword: () => void }) => {
  return (
    <Wrapper>
      <InvalidLink />
      <TypographyText color={black} $fontSize='38px' $lineHeight='46px' $textAlign='center'>
        The link has expired
      </TypographyText>
      <TypographyText
        color={black}
        $fontSize='18px'
        $lineHeight='22px'
        $fontWeight='500'
        $textAlign='center'
        $margin='20px 0 44px 0'
      >
        Get a new password reset link
      </TypographyText>
      <Button
        $fontWeight='700'
        $fontSize='20px'
        $lineHeight='24px'
        $width='676px'
        $height='48px'
        onClick={navigateToForgotPassword}
      >
        Resend a link
      </Button>
    </Wrapper>
  )
}

export default InvalidLinkScreen

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 72px);
  margin: 0 16px;
`
