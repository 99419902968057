import { ENDPOINTS } from 'constants/endpoints'
import httpClient from 'utils/HttpClient'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { CART_ACTION_TYPES } from 'store/reducers/Cart/action-types'
import { IOffer } from 'interfaces/IOffer'
import { IConfirmCartPayload, IUpdateCartProductPayload } from '../types'
import LocalStorage from 'utils/LocalStorage'

export default class CartApi {
  static getCart = createAsyncThunk(CART_ACTION_TYPES.GET_CART, async (_, thunkApi) => {
    const response = await httpClient.request({
      url: ENDPOINTS.CART.GET_CART,
      method: 'GET',
    })

    return thunkApi.fulfillWithValue(response.data)
  })

  static addOffersToCart = createAsyncThunk(
    CART_ACTION_TYPES.ADD_OFFERS_TO_CART,
    async ({ offers, productId }: { offers: IOffer[]; productId: string }, thunkApi) => {
      const response = await httpClient.request({
        url: ENDPOINTS.CART.ADD_OFFERS_TO_CART,
        method: 'POST',
        data: { offers, productId },
      })

      return thunkApi.fulfillWithValue(response.data)
    },
  )

  static updateCart = createAsyncThunk(
    CART_ACTION_TYPES.UPDATE_CART,
    async ({ products }: { products: IUpdateCartProductPayload[] }, thunkApi) => {
      const response = await httpClient.request({
        url: ENDPOINTS.CART.UPDATE_CART,
        method: 'POST',
        data: { products },
      })

      return thunkApi.fulfillWithValue(response.data)
    },
  )

  static deleteCart = createAsyncThunk(CART_ACTION_TYPES.DELETE_CART, async (productId: string, thunkApi) => {
    const response = await httpClient.request({
      url: ENDPOINTS.CART.DELETE_CART(productId),
      method: 'DELETE',
    })

    return thunkApi.fulfillWithValue(response.data)
  })

  static confirmCart = createAsyncThunk(CART_ACTION_TYPES.CONFIRM_CART, async (data: IConfirmCartPayload, thunkApi) => {
    const response = await httpClient.request({
      url: ENDPOINTS.CART.CONFIRM_CART,
      method: 'POST',
      data,
    })

    return thunkApi.fulfillWithValue(response.data)
  })

  static getCurrentCart = createAsyncThunk(CART_ACTION_TYPES.GET_CURRENT_CART, async (products: IOffer[], thunkApi) => {
    const response = await httpClient.request({
      url: ENDPOINTS.CART.GET_CURRENT_CART,
      method: 'POST',
      data: products,
    })
    LocalStorage.removeItem('cart')
    LocalStorage.removeItem('totalQty')
    LocalStorage.removeItem('totalPrice')

    return thunkApi.fulfillWithValue(response.data)
  })
}
