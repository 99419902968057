import SuccessScreen from './components/SuccessScreen'
import useCreateNewPassword from './hooks/useCreateNewPassword'
import CreateNewPasswordForm from './components/CreateNewPasswordForm'
import InvalidLinkScreen from './components/InvalidLinkScreen'
import { useMemo } from 'react'

const CreateNewPassword = () => {
  const { formik, navigateToForgotPassword, navigateToLogin, showSuccessScreen, invitationLinkIsValid } =
    useCreateNewPassword()

  const showForgotPasswordForm = useMemo(
    () => !showSuccessScreen && invitationLinkIsValid,
    [showSuccessScreen, invitationLinkIsValid],
  )
  return (
    <>
      {showForgotPasswordForm && <CreateNewPasswordForm formik={formik} />}

      {invitationLinkIsValid !== null && !invitationLinkIsValid && (
        <InvalidLinkScreen navigateToForgotPassword={navigateToForgotPassword} />
      )}

      {showSuccessScreen && <SuccessScreen navigateToLogin={navigateToLogin} />}
    </>
  )
}

export default CreateNewPassword
