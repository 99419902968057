export const ENDPOINTS = {
  AUTH: {
    LOGIN: '/seller/auth/seller/login',
    LOGOUT: '/seller/auth/logout',
    SIGN_UP: '/seller/auth/seller/sign-up',
    EDIT_CONTACT_INFORMATION: (id: string) => `/seller/sellers/${id}`,
    RESET_PASSWORD: '/seller/auth/seller/password/reset',
    CREATE_NEW_PASSWORD: '/seller/auth/password/update',
    CHECK_TOKEN_EXPIRATION: (token: string) => `/buyer/auth/token/check/${token}`,
    CHANGE_PASSWORD: '/seller/sellers/password/update',
  },
  PRODUCTS: {
    SEARCH: '/seller/products/external',
    UPLOAD_FILE: '/seller/files/seller/upload',
    CONVERT_PRODUCTS_INTO_CART: '/products/anonymous/offer-products-into-cart',
  },
  CART: {
    GET_CART: '/seller/offers/cart',
    ADD_OFFERS_TO_CART: '/seller/offers/cart',
    UPDATE_CART: '/seller/offers/cart/update',
    DELETE_CART: (productId: string) => `/seller/offers/cart/product/${productId}`,
    CONFIRM_CART: '/seller/offers/cart/confirm',
    GET_CURRENT_CART: '/seller/offers/cart-from-items',
  },
}
