import Label from 'components/Label/Label'
import { IWithLabelProps } from 'components/Label/types'

export const withLabel = ({ wrapped, required, htmlFor, label, Component }: IWithLabelProps) => {
  return wrapped ? (
    <>
      <Label required={required} htmlFor={htmlFor}>
        {label}
      </Label>
      {Component}
    </>
  ) : (
    Component
  )
}
