import { memo } from 'react'
import { TableProps, Table as AntdTable } from 'antd'
import styled from 'styled-components'
import { alabaster, black, gallery, mercury, scorpion, silver, white } from 'constants/theme'

const StyledTable: typeof AntdTable = styled(AntdTable)`
  & .ant-table-row:hover > .ant-table-cell {
    background-color: ${silver}20;
    opacity: 0.88;
    cursor: ${(props) => (props.onRow ? 'pointer' : 'initial')};
  }

  &.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0px;
  }
  &.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0px;
  }

  .ant-table-thead > tr > th {
    background-color: ${gallery};
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: ${black};
    text-wrap: nowrap;
    padding: 12px 20px;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 18px;
      padding: 12px 8px;
      font-weight: 500;
    }
  }

  & td.ant-table-cell {
    background-color: ${white};
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${black};
    @media (max-width: 768px) {
      font-size: 14px;
      text-wrap: nowrap;
    }
  }

  &.ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 12px 20px;
    @media (max-width: 768px) {
      padding: 12px 8px;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: 20px;
  }

  & .ant-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: ${white};
    border: 1px solid ${mercury};
  }
  & .ant-pagination-item:hover {
    border: 1px solid ${black};
  }
  & .ant-pagination-item:disabled {
    border: 1px solid ${gallery};
  }
  & .ant-pagination-item > a {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${black};
  }
  & .ant-pagination-item:hover > a {
    color: ${black};
  }
  & .ant-pagination-item-active {
    border: 1px solid ${black};
  }
  & .ant-pagination-item-active > a {
    color: ${black};
  }
  & .ant-pagination-prev > button,
  .ant-pagination-next > button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: ${white};
    border: 1px solid ${mercury};
  }

  & .ant-pagination-prev > button:hover:not(:disabled),
  .ant-pagination-next > button:hover:not(:disabled) {
    border: 1px solid ${black};
  }
  & .ant-pagination-prev > button > span > svg,
  .ant-pagination-next > button > span > svg {
    color: ${black};
  }
  & .ant-pagination-prev > button:disabled > span > svg,
  .ant-pagination-next > button:disabled > span > svg {
    color: ${scorpion};
  }
  & .ant-pagination-prev > button:hover:not(:disabled) > span > svg,
  .ant-pagination-next > button:hover:not(:disabled) > span > svg {
    color: ${black};
  }

  .ant-pagination > .ant-table-pagination > li {
    border-radius: 6px;
  }
  &.ant-table-wrapper .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin-inline: 0px;
    margin-top: 0px;
  }
  &.ant-table-wrapper .ant-table-expanded-row .ant-table-expanded-row-level-1 .ant-table-cell {
    padding: 0;
  }
  &.ant-table-wrapper tr.ant-table-expanded-row > td {
    padding: 0 0 16px 0;
  }

  & .ant-table-expanded-row .ant-table-thead > tr > th {
    background-color: ${alabaster};
  }
  &.ant-table-wrapper .ant-table-cell {
    @media (max-width: 768px) {
      padding: 12px 8px;
    }
  }
`

const Table = ({
  columns,
  dataSource,
  onRow,
  onChange,
  scroll,
  pagination,
  rowKey,
  showHeader,
  rowClassName,
  className,
  rowSelection,
  bordered,
  loading,
  title,
  size,
  expandable,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TableProps<any>) => {
  return (
    <StyledTable
      columns={columns}
      dataSource={dataSource}
      onChange={onChange}
      scroll={scroll}
      style={{ width: '100%' }}
      pagination={pagination}
      onRow={onRow}
      rowKey={rowKey}
      showHeader={showHeader}
      rowClassName={rowClassName}
      className={className}
      rowSelection={rowSelection}
      bordered={bordered}
      loading={loading}
      title={title}
      size={size}
      expandable={expandable}
    />
  )
}

export default memo(Table)
