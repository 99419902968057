import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { commonReducerName } from './action-types'

interface ICommonState {
  processingActions: string[]
}

const initialState: ICommonState = {
  processingActions: [],
}

export const commonSlice = createSlice({
  name: commonReducerName,
  initialState,
  reducers: {
    addAsyncThunkType: (state, action: PayloadAction<string>) => {
      state.processingActions.push(action.payload)
    },
    removeAsyncThunkType: (state, action: PayloadAction<string>) => {
      const firstMatchIndex = state.processingActions.indexOf(action.payload)
      if (firstMatchIndex !== -1) {
        state.processingActions.splice(firstMatchIndex, 1)
      }
    },
  },
})

export default commonSlice.reducer

export const commonActions = commonSlice.actions
