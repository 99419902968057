import { productsActions } from 'store/reducers/Products/ProductSlice'
import { useAppDispatch } from './useStore'
import { RcFile } from 'antd/es/upload'
import { openSuccessMessage, openErrorMessage } from 'components/Notification/Notification'
import { useState, useCallback, useEffect } from 'react'
import { EFileStatus } from 'interfaces/IUploadedFile'
import { ALLOWED_FILE_TYPES } from 'constants/constants'
import FileUploaderApi from 'utils/FileUploaderApi'
import { useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { EDeliveryMethod } from 'enums/EDeliveryMethod'

export const useUploadFile = (
  open: boolean,
  onOk: () => void,
  deliveryMethod: EDeliveryMethod | undefined,
  takeAll: boolean,
) => {
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState<RcFile>()
  const [percent, setPercent] = useState<number>(0)
  const [status, setStatus] = useState<EFileStatus>(EFileStatus.DEFAULT)
  const accept = ALLOWED_FILE_TYPES.join(', ')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigateToSuccessScreen = useCallback(() => navigate(URLS.DASHBOARD.CONFIRMED_CART), [navigate])

  const uploadFile = useCallback(() => {
    setIsUploading(true)
    if (file) {
      dispatch(productsActions.setLoading(true))

      const formData = new FormData()
      formData.append('file', file)

      formData.append('shipping', (deliveryMethod === EDeliveryMethod.SHIPPING).toString())
      formData.append('takeAll', takeAll.toString())
      formData.append('deliveryAddress', deliveryMethod === EDeliveryMethod.DROPOFF ? 'Dropped off' : 'Shipped')

      dispatch(FileUploaderApi.uploadChunk(formData))
        .unwrap()
        .then(() => {
          setPercent(100)
          setStatus(EFileStatus.COMPLETED)
          dispatch(productsActions.setLoading(false))
          onOk()
          setIsUploading(false)
          openSuccessMessage({ content: 'File upload successfully' })
          navigateToSuccessScreen()
        })
        .catch(() => {
          setStatus(EFileStatus.FAIL)
          setIsUploading(false)
        })
    }
  }, [dispatch, file, onOk, navigateToSuccessScreen, deliveryMethod, takeAll])

  const onUnAllowedFileTypeUpload = useCallback((file: RcFile) => {
    openErrorMessage({ content: `File ${file.name} has an invalid file format` })
  }, [])

  const beforeUpload = useCallback(
    (file: RcFile) => {
      if (ALLOWED_FILE_TYPES.includes(file.type)) {
        setStatus(EFileStatus.DEFAULT)
        setFile(file)
      } else {
        onUnAllowedFileTypeUpload(file)
      }
      return false
    },
    [onUnAllowedFileTypeUpload],
  )

  const deleteFile = () => {
    setFile(undefined)
    setPercent(0)
    setStatus(EFileStatus.DEFAULT)
  }

  const downloadTemplate = () => {
    const link = document.createElement('a')
    link.href = '/template.xlsx'
    link.setAttribute('download', 'template.xlsx')
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
  }

  useEffect(() => {
    if (open) {
      setFile(undefined)
      setPercent(0)
      setStatus(EFileStatus.DEFAULT)
    }
  }, [open])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isUploading) {
        const message = 'The file is still uploading. Are you sure you want to leave the page?'
        event.returnValue = message
        return message
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isUploading])

  return { beforeUpload, accept, file, percent, status, deleteFile, uploadFile, downloadTemplate }
}
