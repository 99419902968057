export const Complete = () => {
  return (
    <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.99935 1.66663C3.78518 1.66663 1.16602 4.28579 1.16602 7.49996C1.16602 10.7141 3.78518 13.3333 6.99935 13.3333C10.2135 13.3333 12.8327 10.7141 12.8327 7.49996C12.8327 4.28579 10.2135 1.66663 6.99935 1.66663ZM9.78768 6.15829L6.48018 9.46579C6.39852 9.54746 6.28768 9.59413 6.17102 9.59413C6.05435 9.59413 5.94352 9.54746 5.86185 9.46579L4.21102 7.81496C4.04185 7.64579 4.04185 7.36579 4.21102 7.19663C4.38018 7.02746 4.66018 7.02746 4.82935 7.19663L6.17102 8.53829L9.16935 5.53996C9.33852 5.37079 9.61852 5.37079 9.78768 5.53996C9.95685 5.70913 9.95685 5.98329 9.78768 6.15829Z'
        fill='#12B368'
      />
    </svg>
  )
}
