import Input from 'components/Input/Input'
import { IOffer } from 'interfaces/IOffer'
import { IProductItem } from 'interfaces/IProductItem'
import { handleNumericInputKeyDown } from 'utils/helpers'

export const renderColumns = ({ offers, disabled }: { offers: IOffer[]; disabled: boolean }) => {
  const columns = [
    {
      title: 'SIZE',
      dataIndex: 'size',
      key: 'size',
      width: '450px',
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '450px',
      render: (_: string, record: IProductItem) => {
        const idx = offers.findIndex((offer: IOffer) => offer.size === record.size)
        const quantity = offers.find((offer: IOffer) => offer.size === record.size)?.quantity

        return (
          <Input
            name={`offers[${idx}].quantity`}
            value={quantity}
            $borderRadius='0'
            $width='100px'
            $height='35px'
            type='number'
            onKeyDown={handleNumericInputKeyDown}
            disabled={disabled}
          />
        )
      },
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      key: 'price',
      render: (_: string, record: IProductItem) => {
        const idx = offers.findIndex((offer: IOffer) => offer.size === record.size)
        const price = offers.find((offer: IOffer) => offer.size === record.size)?.price
        return (
          <Input
            name={`offers[${idx}].price`}
            value={price}
            $borderRadius='0'
            $width='100px'
            $height='35px'
            type='price'
            onKeyDown={handleNumericInputKeyDown}
            disabled={disabled}
          />
        )
      },
      width: '450px',
    },
  ]
  return columns
}
