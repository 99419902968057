export const cartReducerName = 'cart'

export const CART_ACTION_TYPES = {
  GET_CART: cartReducerName + '/get-cart',
  ADD_OFFERS_TO_CART: cartReducerName + '/add-offers-to-cart',
  UPDATE_CART: cartReducerName + '/update-cart',
  DELETE_CART: cartReducerName + '/delete-cart',
  CONFIRM_CART: cartReducerName + '/confirm-cart',
  GET_CURRENT_CART: cartReducerName + '/get-current-cart',
}
