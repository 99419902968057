import { ENDPOINTS } from 'constants/endpoints'
import httpClient from 'utils/HttpClient'
import { createAsyncThunk } from '@reduxjs/toolkit'
import AbortRequest from 'utils/AbortRequest'
import { PRODUCTS_ACTION_TYPES } from 'store/reducers/Products/action-types'
import { IOffer } from 'interfaces/IOffer'

const AbortRequestController = new AbortRequest()

export default class ProductsApi {
  static searchProducts = createAsyncThunk(
    PRODUCTS_ACTION_TYPES.SEARCH_PRODUCTS,
    async (params: { search: string; page: number }, thunkApi) => {
      if (AbortRequestController.controller) {
        AbortRequestController.abort()
      }
      AbortRequestController.init()

      const response = await httpClient.request({
        url: ENDPOINTS.PRODUCTS.SEARCH,
        method: 'GET',
        params,
        signal: AbortRequestController.signal,
      })

      return thunkApi.fulfillWithValue({ products: response.data, search: params.search, page: params.page })
    },
  )

  static convertProductsToCart = createAsyncThunk(
    PRODUCTS_ACTION_TYPES.CONVERT_PRODUCTS_INTO_CART,
    async (data: IOffer[], thunkApi) => {
      const response = await httpClient.request({
        url: ENDPOINTS.PRODUCTS.CONVERT_PRODUCTS_INTO_CART,
        method: 'POST',
        data: { products: data },
      })

      return thunkApi.fulfillWithValue(response.data)
    },
  )
}
