import Button from 'components/Button/Button'
import styled from 'styled-components'
import { FormikProvider } from 'formik'
import TypographyText from 'components/TypographyText/TypographyText'
import { black, gray } from 'constants/theme'
import { RightOutlined } from '@ant-design/icons'
import Icon from 'components/Icon/Icon'
import { useSignUp } from 'modules/Auth/hooks/useSignUp'
import SignUpForm from 'modules/Auth/components/SignUpForm'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`

const NavigationWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin: 20px 0 40px 40px;
`

const SignUp = () => {
  const { formik, navigateBackward } = useSignUp()

  return (
    <Wrapper>
      <NavigationWrapper>
        <TypographyText
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='22px'
          $color={gray}
          $cursor='pointer'
          onClick={navigateBackward}
        >
          LOGIN
        </TypographyText>
        <Icon Component={RightOutlined} $color={gray} $fontSize='16px' onClick={navigateBackward} />
        <TypographyText $fontSize='16px' $fontWeight='400' $lineHeight='22px' $color={black}>
          SIGN UP
        </TypographyText>
      </NavigationWrapper>
      <ContentWrapper>
        <TypographyText color={black} $fontSize='38px' $lineHeight='45px' $textAlign='center' $margin='0 0 30px 0'>
          Sign up
        </TypographyText>
        <FormikProvider value={formik}>
          <SignUpForm />
          <Button
            $fontWeight='700'
            $fontSize='20px'
            $lineHeight='24px'
            $width='676px'
            $height='48px'
            $margin='12px 0 0 0'
            disabled={!formik.isValid}
            onClick={formik.handleSubmit}
          >
            Sign up
          </Button>
        </FormikProvider>
      </ContentWrapper>
    </Wrapper>
  )
}

export default SignUp
