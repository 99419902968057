import { Field, FieldProps } from 'formik'
import styled from 'styled-components'

import { useFormikErrors } from 'hooks/useFormikErrors'
import { errorRed } from 'constants/theme'
import CustomInput from './components/CustomInput'
import { ICustomOnChangeProp, InputProps } from './types'

import useInputHandlers from 'hooks/useInputHandlers'
import { withLabel } from 'components/Label/utils/withLabel'
import PasswordInput from './components/PasswordInput'
import PhoneInput from './components/PhoneInput/PhoneInput'
import { TextFieldProps } from '@mui/material'
import PriceInput from './components/PriceInput'

export const FieldError = styled('div')`
  color: ${errorRed};
  font-size: 12px;
  line-height: 15px;
  margin-top: 4px;
`

export const FieldRow = styled('div')<{
  $rowHeight?: string
  $rowMargin?: string
  $rowWidth?: string
}>`
  height: ${(props) => props.$rowHeight};
  margin: ${(props) => props.$rowMargin};
  width: ${(props) => props.$rowWidth};
`

const FormikChildren = ({
  name,
  onChange,
  onBlur,
  type,
  value,
  formik,
  $pattern,
  ...props
}: TextFieldProps &
  InputProps &
  ICustomOnChangeProp & {
    formik: FieldProps
  }) => {
  const { form, ...formikProps } = formik

  const { showErrorMessage, errorMessage } = useFormikErrors(formik)

  const { handleChange, handleBlur } = useInputHandlers({
    onChange,
    onBlur,
    $pattern,
    ...formik,
    form,
  })

  const renderInput = () => {
    switch (type) {
      case 'password':
        return (
          <PasswordInput
            name={name}
            onChange={handleChange}
            value={value}
            onBlur={handleBlur}
            type={'password'}
            error={formikProps.meta.error && formikProps.meta.touched}
            {...formikProps}
            {...props}
          />
        )

      case 'phone':
        return (
          <PhoneInput
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value}
            $allowedSymbols={props.$allowedSymbols}
            error={formikProps.meta.error && formikProps.meta.touched}
            {...formikProps}
            {...props}
          />
        )

      case 'price':
        return (
          <PriceInput
            name={name}
            onChange={handleChange}
            value={value}
            onBlur={handleBlur}
            type='number'
            error={Boolean(formikProps.meta.error && formikProps.meta.touched)}
            {...formikProps}
            {...props}
          />
        )

      default:
        return (
          <CustomInput
            name={name}
            onChange={handleChange}
            value={value}
            onBlur={handleBlur}
            type={type}
            error={Boolean(formikProps.meta.error && formikProps.meta.touched)}
            {...formikProps}
            {...props}
          />
        )
    }
  }

  return (
    <FieldRow $rowHeight={props.$rowHeight} $rowMargin={props.$rowMargin}>
      {renderInput()}
      {showErrorMessage && <FieldError role='alert'>{errorMessage}</FieldError>}
    </FieldRow>
  )
}

const Input = ({ validate, ...props }: InputProps & ICustomOnChangeProp & TextFieldProps) => {
  const { label, required } = props
  return withLabel({
    Component: (
      <Field name={props.name} validate={validate}>
        {(formik: FieldProps) => (
          <FormikChildren formik={formik} {...props} value={props.value || formik.field.value} />
        )}
      </Field>
    ),
    label,
    required,
    htmlFor: props.name,
  })
}

export default Input
