import { Spin } from 'antd'
import styled from 'styled-components'

import { black, white } from 'constants/theme'
import { ISpinnerProps } from './types'

const LoaderWrapper = styled('div')`
  position: fixed;
  z-index: 10001;
  background: ${white};
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
`

export const Loader = styled(Spin)<{ $fontSize?: string; $margin?: string }>`
  &.ant-spin-spinning {
    display: flex;
  }
  & .ant-spin-dot {
    font-size: ${(props) => props.$fontSize || '36px'};
    margin: ${(props) => props.$margin};
  }
  & .ant-spin-dot-item {
    background: ${black};
    opacity: 0.5;
  }
`
const Spinner = ({ loading, size = 'large', indicator, delay, tip, wrapperClassName }: ISpinnerProps) => (
  <>
    {loading && (
      <LoaderWrapper>
        <Loader
          size={size}
          spinning={loading}
          indicator={indicator}
          delay={delay}
          tip={tip}
          wrapperClassName={wrapperClassName}
        />
      </LoaderWrapper>
    )}
  </>
)

export default Spinner
