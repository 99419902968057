import AntdIcon from '@ant-design/icons'
import styled from 'styled-components'
import { IconProps } from './types'

const StyledIcon = styled(AntdIcon)<IconProps>`
  &.anticon {
    display: ${(props) => props.$display};
    background: ${(props) => props.$background};
    color: ${(props) => props.$color || 'inherit'};
    font-size: ${(props) => props.$fontSize};
    align-self: ${(props) => props.$alignSelf};
    margin: ${(props) => props.$margin};
    position: ${(props) => props.$position};
    z-index: ${(props) => props.$zIndex};
    top: ${(props) => props.$top};
    bottom: ${(props) => props.$bottom};
    right: ${(props) => props.$right};
    left: ${(props) => props.$left};
    transform: ${(props) => props.$transform};
    transition: ${(props) => props.$transition};
    height: ${(props) => props.$height};
    width: ${(props) => props.$width};
    cursor: ${(props) => props.$cursor || 'pointer'};
    padding: ${(props) => props.$padding};
    border-radius: ${(props) => props.$borderRadius};
    border: ${(props) => props.$border};

    ${(props) => props.$responsiveStyles}
  }
  .anticon svg {
    height: ${(props) => props.$height};
    width: ${(props) => props.$width};
  }
`

const Icon = ({
  Component,
  onClick,
  $background,
  $color,
  $fontSize,
  $alignSelf,
  $margin,
  $position,
  $top,
  $right,
  $transform,
  $padding,
  $responsiveStyles,
  ...props
}: IconProps) => {
  return (
    <StyledIcon
      component={Component}
      onClick={onClick}
      $background={$background}
      $color={$color}
      $fontSize={$fontSize}
      $margin={$margin}
      $alignSelf={$alignSelf}
      $position={$position}
      $top={$top}
      $right={$right}
      $transform={$transform}
      $padding={$padding}
      $responsiveStyles={$responsiveStyles}
      {...props}
    />
  )
}

export default Icon
