import { URLS } from 'constants/urls'
import { useAppSelector } from 'hooks/useStore'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useUploadFileModal = () => {
  const { isAuthenticated } = useAppSelector((state) => state.authReducer)
  const [uploadModalState, setOpen] = useState(false)
  const navigate = useNavigate()

  const openUploadInventoryModal = () => {
    if (isAuthenticated) {
      setOpen(true)
    } else {
      navigate(URLS.AUTH.LOGIN)
    }
  }

  const handleUploadCancel = () => setOpen(false)

  const handleUploadOk = () => {
    setOpen(false)
  }

  return { openUploadInventoryModal, handleUploadOk, handleUploadCancel, uploadModalState }
}
