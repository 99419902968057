import { Progress, Spin } from 'antd'
import { Delete } from 'assets/Delete'
import { File } from 'assets/File'
import { Complete } from 'assets/tick-circle'
import Button from 'components/Button/Button'
import Icon from 'components/Icon/Icon'
import TypographyText from 'components/TypographyText/TypographyText'
import { getReadableFileSizeString } from 'constants/constants'
import { black, guardsmansRed, gallery, gray } from 'constants/theme'
import { EFileStatus, IUploadedFile } from 'interfaces/IUploadedFile'
import styled from 'styled-components'

const StyledContainre = styled('div')`
  border: 1px solid ${gallery};
  border-radius: 6px;
  padding: 15px;
`

const StyledFirstRow = styled('div')<{ color: string; fontWeight: string }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
`
const StyledSecondRow = styled('div')`
  padding-left: 30px;
  display: flex;
  color: ${gray};
`

const StatusContainer = styled('div')`
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: ${black};
`

const FailContainer = styled('div')`
  padding-left: 30px;
`

const UploadedFile = ({ file, percent, status, deleteFile, tryAgain }: IUploadedFile) => {
  const FileElement = () => <File fill={status === EFileStatus.FAIL ? guardsmansRed : black} />

  return (
    <StyledContainre>
      <StyledFirstRow
        color={status === EFileStatus.FAIL ? guardsmansRed : black}
        fontWeight={status === EFileStatus.FAIL ? '700' : '400'}
      >
        <Icon Component={FileElement} $margin='0 10px 0 0' />
        <TypographyText $fontSize='16px' $flexGrow='1'>
          {status === EFileStatus.FAIL ? 'Uploaded failed, please try again' : file.name}
        </TypographyText>
        <Icon Component={Delete} $width='18px' $height='18px' onClick={deleteFile} />
      </StyledFirstRow>
      {status === EFileStatus.FAIL ? (
        <FailContainer>
          <TypographyText $fontSize='16px' $fontWeight='400'>
            {file.name}
          </TypographyText>
          <Button variant='outlined' $width='118px' $height='40px' $margin='5px 0 0' onClick={tryAgain}>
            Try Again
          </Button>
        </FailContainer>
      ) : (
        <StyledSecondRow>
          {getReadableFileSizeString(file.size) + ' •'}
          {status === EFileStatus.PROGRESS && (
            <StatusContainer>
              <Spin size='small' />
              <TypographyText $padding='0 0 0 5px'>Uploading...</TypographyText>
            </StatusContainer>
          )}
          {status === EFileStatus.COMPLETED && (
            <StatusContainer>
              <Icon Component={Complete} />
              <TypographyText $padding='0 0 0 5px'>Completed</TypographyText>
            </StatusContainer>
          )}
        </StyledSecondRow>
      )}
      {status === EFileStatus.PROGRESS && <Progress percent={percent} />}
    </StyledContainre>
  )
}

export default UploadedFile
