import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'
import TypographyText from 'components/TypographyText/TypographyText'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import CartApi from 'modules/Cart/services/CartApi'
import ProductsApi from 'modules/Products/services/ProductsApi'
import React, { useCallback, useMemo, useState } from 'react'
import { cartActions } from 'store/reducers/Cart/CartSlice'
import styled from 'styled-components'

const useDeleteCart = (productId: string | undefined) => {
  const [isDeleteCartModalOpen, setIsDeleteCartModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const { isAuthenticated } = useAppSelector((state) => state.authReducer)
  const openDeleteCartModal = () => setIsDeleteCartModalOpen(true)
  const closeDeleteCartModal = () => setIsDeleteCartModalOpen(false)

  const dispatch = useAppDispatch()

  const turnOnEditMode = () => setIsEditMode(true)
  const turnOffEditMode = () => setIsEditMode(false)

  const deleteCart = useCallback(() => {
    if (productId) {
      if (isAuthenticated) {
        dispatch(CartApi.deleteCart(productId))
          .unwrap()
          .then(() => {
            setIsEditMode(false)
            closeDeleteCartModal()
          })
      } else {
        dispatch(ProductsApi.convertProductsToCart([]))
          .unwrap()
          .then(() => {
            setIsEditMode(false)
            dispatch(cartActions.deleteProductInCart({ deletedProductId: productId }))
            closeDeleteCartModal()
          })
      }
    }
  }, [dispatch, productId, isAuthenticated])

  const DeleteCartModal = useMemo(() => {
    return (
      <Modal open={isDeleteCartModalOpen} onCancel={closeDeleteCartModal} width={350} hideFooter hideHeader>
        <ModalTextWrapper>
          <TypographyText $fontSize='20px' $lineHeight='24px' $fontWeight='600'>
            Delete all items?
          </TypographyText>
          <TypographyText $fontSize='16px' $lineHeight='20px'>
            Are you sure you want to delete all items?
          </TypographyText>
        </ModalTextWrapper>
        <ButtonWrapper>
          <Button
            variant='outlined'
            $fontSize={'14px'}
            $width={'78px'}
            $height={'32px'}
            $margin={'0 8px 0 0'}
            onClick={closeDeleteCartModal}
          >
            Cancel
          </Button>
          <Button $fontSize={'14px'} $width={'86px'} $height={'32px'} onClick={deleteCart}>
            Delete
          </Button>
        </ButtonWrapper>
      </Modal>
    )
  }, [isDeleteCartModalOpen, deleteCart])

  return { openDeleteCartModal, DeleteCartModal, isEditMode, turnOnEditMode, turnOffEditMode }
}

export default useDeleteCart

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`
const ModalTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
