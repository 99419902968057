import { IUseInputMask } from 'components/Input/types'
import React, { useState, KeyboardEvent } from 'react'
import { formatNumberWithPhoneMask, unformatPhoneNumber } from 'utils/helpers'

export const usePhoneMask = ({ value, onChange, $allowedSymbols }: IUseInputMask) => {
  const [key, setKey] = useState<string>('')

  const onKeyDown = (event: KeyboardEvent) => setKey(event.key)

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault() // Prevent the default paste behavior
    const target = event.target as HTMLInputElement
    const pastedText = event.clipboardData.getData('text') // Get the pasted text
    const selectionStart = target.selectionStart as number // Get the current caret position
    const selectionEnd = target.selectionEnd as number

    const newValue =
      unformatPhoneNumber(value.substring(0, selectionStart), false) + // Get the text before the caret
      pastedText + // Add the pasted text
      unformatPhoneNumber(value.substring(selectionEnd), false) // Get the text after the caret

    if ($allowedSymbols) {
      const formattedValue = formatNumberWithPhoneMask(newValue, $allowedSymbols)

      target.value = formattedValue
      onChange && onChange(event as never)

      window.requestAnimationFrame(() => {
        const unformattedValue = unformatPhoneNumber(value.substring(0, selectionStart), false) + pastedText

        const newCaretPosition = formatNumberWithPhoneMask(unformattedValue, $allowedSymbols).length

        target.setSelectionRange(newCaretPosition, newCaretPosition)
      })
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const element = event.target
    let caret = element.selectionStart as number
    let value: string | string[] = element.value.split('')
    let formattedValue = ''

    // update value and caret around delimiters
    if ((caret === 4 || caret === 8) && key !== 'Delete' && key !== 'Backspace') {
      caret++
    } else if ((caret === 3 || caret === 7) && key === 'Backspace') {
      value.splice(caret - 1, 1)
      caret--
    } else if ((caret === 3 || caret === 7) && key === 'Delete') {
      value.splice(caret, 1)
    }

    if ($allowedSymbols) {
      if (key.length === 1 && $allowedSymbols.test(key))
        // update caret for non-digits
        caret--
      formattedValue = formatNumberWithPhoneMask(value.join(''), $allowedSymbols)
    }
    value = value.join('')

    event.target.value = formattedValue

    onChange && onChange(event)

    window.requestAnimationFrame(() => {
      element.setSelectionRange(caret, caret)
    })
  }

  return {
    onKeyDown,
    onPaste,
    handleChange,
  }
}
