import { Modal as AntdModal } from 'antd'
import styled from 'styled-components'

import { alto, silver, white, wildSand } from 'constants/theme'
import { IModalProps } from './types'

export const StyledModal = styled(AntdModal)<IModalProps>`
  position: ${(props) => props.$position};
  top: ${(props) => props.$top};
  left: ${(props) => props.$left};
  transform: ${(props) => props.$transform};
  &.ant-modal .ant-modal-content {
    ${(props) => props.$responsiveStyles}
    padding: ${(props) => props.bodyPadding};
  }
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-btn {
    border-radius: 6px;
  }
  .ant-modal-footer .ant-btn-loading-icon {
    display: inline-flex;
  }
  & .ant-modal-close {
    color: black;
  }
  ${(props) =>
    !props.hideHeader &&
    !props.hideFooter &&
    !props.footer &&
    `
.ant-btn-default {
  background-color: ${white};
  border: 1px solid black;
  color: black;
}
`}
  .ant-btn-primary {
    background-color: black;
    border: none;
  }
  .ant-btn-primary[disabled] {
    color: ${silver};
    border: 1px solid ${alto};
    background: ${wildSand};
  }
  .ant-btn:not([disabled]):hover {
    opacity: 0.7;
  }

  .ant-modal-title {
    font-weight: 600;
  }
  .ant-modal-close-x,
  .ant-modal-header {
    display: ${(props) => (props.hideHeader ? 'none' : 'block')};
  }
  .ant-modal-footer {
    display: ${(props) => (props.hideFooter ? 'none' : 'block')};
  }
`

const Modal = (props: IModalProps) => {
  return (
    <StyledModal
      mask={props.mask}
      maskClosable={props.maskClosable}
      title={props.title}
      okButtonProps={props.okButtonProps}
      open={props.open}
      onOk={props.onOk}
      onCancel={props.onCancel}
      width={props.width}
      okText={props.okText}
      cancelText={props.cancelText}
      footer={props.footer}
      hideHeader={props.hideHeader}
      hideFooter={props.hideFooter}
      destroyOnClose={props.destroyOnClose}
      closable={props.closable}
      height={props.height}
      wrapProps={props.wrapProps}
      $position={props.$position}
      $top={props.$top}
      $left={props.$left}
      $transform={props.$transform}
      modalRender={props.modalRender}
      bodyStyle={props.bodyStyle}
      afterClose={props.afterClose}
      bodyPadding={props.bodyPadding}
      getContainer={props.getContainer}
      centered={props.centered}
      $responsiveStyles={props.$responsiveStyles}
    >
      {props.children}
    </StyledModal>
  )
}

export default Modal
