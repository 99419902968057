import { useAppSelector } from 'hooks/useStore'
import React from 'react'
import styled from 'styled-components'
import TypographyText from 'components/TypographyText/TypographyText'
import { black, gray, mineShaft, silver, white } from 'constants/theme'
import useCart from '../utils/useCart'
import Icon from 'components/Icon/Icon'
import { ArrowDownOutlined, ArrowUpOutlined, RightOutlined } from '@ant-design/icons'
import { CART_CHECKOUT_INFO } from 'constants/constants'
import Button from 'components/Button/Button'
import Collapse from 'components/Collapse/Collapse'
import EmptyState from 'components/EmptyState/EmptyState'
import EmptyOrders from 'assets/EmptyOrders.png'
import FinalizeOffer from './FinalizeOffer'

const Cart = () => {
  const { data, totalQty, loading } = useAppSelector((state) => state.cartReducer)

  const { navigateToHome, offers, handleConfirmOffer, AuthModal, isFinalizeOffer } = useCart()

  const isCartEmpty = data.map((cart) => cart.products).flat().length <= 0
  return (
    <>
      {!isCartEmpty && (
        <Wrapper>
          <NavigationWrapper>
            <TypographyText
              $fontSize='16px'
              $fontWeight='400'
              $lineHeight='22px'
              $color={gray}
              onClick={navigateToHome}
              $cursor='pointer'
            >
              HOMEPAGE
            </TypographyText>
            <Icon Component={RightOutlined} $color={gray} $fontSize='16px' onClick={navigateToHome} />
            <TypographyText $fontSize='16px' $fontWeight='400' $lineHeight='22px' color={black}>
              MY OFFERS
            </TypographyText>
          </NavigationWrapper>
          <TypographyText $fontSize='32px' $lineHeight='38px' $fontWeight='600'>
            {isFinalizeOffer ? 'Finalizing the offer' : 'My offers'}
          </TypographyText>
          {isFinalizeOffer ? (
            <FinalizeOffer />
          ) : (
            <ContentWrapper>
              <LocationsWrapper>
                {data.map((_, cartIndex) => {
                  return (
                    <Collapse
                      key={cartIndex}
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <Icon Component={ArrowUpOutlined} $width='20px' $height='20px' />
                        ) : (
                          <Icon Component={ArrowDownOutlined} $width='20px' $height='20px' />
                        )
                      }
                      expandIconPosition='end'
                      items={offers}
                      $contentBorderRadius='12px'
                    />
                  )
                })}
              </LocationsWrapper>
              <CheckoutWrapper>
                <OfferWrapper>
                  <TypographyText $fontSize='18px' $lineHeight='22px' $fontWeight='500' $margin='0 0 16px 0'>
                    OFFER INFORMATION
                  </TypographyText>
                  <TotalWrapper>
                    <TypographyText $fontSize='16px' $lineHeight='20px'>
                      Products
                    </TypographyText>
                    <TypographyText $fontSize='18px' $lineHeight='22px' $fontWeight='500'>
                      {data.map((cart) => cart.products)[0].length}
                    </TypographyText>
                  </TotalWrapper>
                  <TotalWrapper>
                    <TypographyText $fontSize='16px' $lineHeight='20px'>
                      Items
                    </TypographyText>
                    <TypographyText $fontSize='18px' $lineHeight='22px' $fontWeight='500'>
                      {totalQty}
                    </TypographyText>
                  </TotalWrapper>
                </OfferWrapper>

                <Button $width='373px' $height='40px' onClick={handleConfirmOffer}>
                  Confirm offer
                </Button>
                <CheckoutDetails>
                  {CART_CHECKOUT_INFO.map((item) => (
                    <CheckoutDetail key={item}>{item}</CheckoutDetail>
                  ))}
                </CheckoutDetails>
              </CheckoutWrapper>
            </ContentWrapper>
          )}
        </Wrapper>
      )}

      {!loading && isCartEmpty && (
        <Wrapper>
          <NavigationWrapper>
            <TypographyText
              $fontSize='16px'
              $fontWeight='400'
              $lineHeight='22px'
              $color={gray}
              onClick={navigateToHome}
              $cursor='pointer'
            >
              HOMEPAGE
            </TypographyText>
            <Icon Component={RightOutlined} $color={gray} $fontSize='16px' onClick={navigateToHome} />
            <TypographyText $fontSize='16px' $fontWeight='400' $lineHeight='22px' color={black}>
              MY OFFERS
            </TypographyText>
          </NavigationWrapper>
          <TypographyText $fontSize='32px' $lineHeight='38px' $fontWeight='600'>
            My offers
          </TypographyText>
          <EmptyState
            src={EmptyOrders}
            title='You have not added any offers yet'
            buttonText='Go to homepage'
            onClick={navigateToHome}
            buttonWidth='209px'
          />
        </Wrapper>
      )}
      {AuthModal}
    </>
  )
}

export default Cart

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 28px 40px;
`
const NavigationWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 40px;
`
const ContentWrapper = styled('div')`
  display: flex;
  gap: 20px;
  margin-top: 28px;
  justify-content: space-between;
`
const CheckoutWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background: ${white};
  border-radius: 8px;
  padding: 12px;
  height: fit-content;
`
const TotalWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`
const OfferWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid ${silver};
`
const CheckoutDetails = styled('ul')`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
  padding: 0 0 12px 24px;
  max-width: 350px;
  gap: 16px;
  list-style: none;
`
const CheckoutDetail = styled('li')`
  position: relative;
  gap: 12px;
  color: ${mineShaft};
  font-size: 14px;
  line-height: 18px;
  &::before {
    content: '';
    position: absolute;
    background: ${silver};
    width: 8px;
    border-radius: 50%;
    height: 8px;
    top: 50%;
    left: -24px;
  }
`
const LocationsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`
