import { Typography as AntdTypography } from 'antd'
import styled from 'styled-components'

import { ITypographyTextProps } from './types'

const StyledTypographyText = styled(AntdTypography.Text)<ITypographyTextProps>`
  &,
  & a {
    color: ${(props) => props.$color || 'inherit'};
    margin: ${(props) => props.$margin};
    font-size: ${(props) => props.$fontSize};
    text-align: ${(props) => props.$textAlign};
    line-height: ${(props) => props.$lineHeight};
    font-weight: ${(props) => props.$fontWeight};
    padding: ${(props) => props.$padding};
    display: ${(props) => props.$display};
    justify-content: ${(props) => props.$justifyContent};
    cursor: ${(props) => props.$cursor};
    flex-grow: ${(props) => props.$flexGrow};
    text-transform: ${(props) => props.$transform};
    text-decoration: ${(props) => props.$textDecoration};
    text-underline-offset: ${(props) => props.$textUnderlineOffset};
  }
  & .ant-typography-expand,
  & .ant-typography-edit,
  & .ant-typography-copy {
    line-height: initial;
  }
  & a {
    text-decoration: underline;
  }

  & a:hover {
    opacity: 0.8;
    color: ${(props) => props.$color};
    text-decoration: none;
  }
  ${(props) => props.$responsiveStyles}
`

const TypographyText = ({
  children,
  $margin,
  $color,
  strong,
  italic,
  onClick,
  $cursor,
  $responsiveStyles,
  ...rest
}: ITypographyTextProps) => {
  return (
    <StyledTypographyText
      $color={$color}
      $margin={$margin}
      strong={strong}
      italic={italic}
      $cursor={$cursor}
      onClick={onClick}
      $responsiveStyles={$responsiveStyles}
      {...rest}
    >
      {children}
    </StyledTypographyText>
  )
}

export default TypographyText
