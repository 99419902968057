export const FileIcon = () => {
  return (
    <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.9272 6.9395C14.8917 6.8581 14.8419 6.78369 14.7803 6.71975L10.2802 2.21975C10.2163 2.15809 10.1419 2.10831 10.0605 2.07275C10.038 2.06225 10.014 2.05625 9.99 2.048C9.92725 2.02664 9.86192 2.01378 9.79575 2.00975C9.78 2.00825 9.76575 2 9.75 2H4.5C3.67275 2 3 2.67275 3 3.5V15.5C3 16.3273 3.67275 17 4.5 17H13.5C14.3273 17 15 16.3273 15 15.5V7.25C15 7.23425 14.9917 7.22 14.9902 7.2035C14.9866 7.13729 14.9737 7.07191 14.952 7.00925C14.9445 6.98525 14.9377 6.962 14.9272 6.9395ZM12.4395 6.5H10.5V4.5605L12.4395 6.5ZM4.5 15.5V3.5H9V7.25C9 7.44891 9.07902 7.63968 9.21967 7.78033C9.36032 7.92098 9.55109 8 9.75 8H13.5L13.5015 15.5H4.5Z'
        fill='#398647'
      />
      <path d='M6 9.5H12V11H6V9.5ZM6 12.5H12V14H6V12.5ZM6 6.5H7.5V8H6V6.5Z' fill='#398647' />
    </svg>
  )
}
