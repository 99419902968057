const Logo = () => {
  return (
    <svg width='208' height='55' viewBox='0 0 208 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M34.3493 23.4105C34.3493 26.164 33.8672 28.5436 32.903 30.5492C31.9388 32.5549 30.6162 34.1016 28.935 35.1894C27.2539 36.2772 25.3338 36.8211 23.1746 36.8211C21.0155 36.8211 19.0954 36.2772 17.4143 35.1894C15.7331 34.1016 14.4105 32.5549 13.4463 30.5492C12.4821 28.5436 12 26.164 12 23.4105C12 20.6571 12.4821 18.2775 13.4463 16.2719C14.4105 14.2662 15.7331 12.7195 17.4143 11.6317C19.0954 10.5439 21.0155 10 23.1746 10C25.3338 10 27.2539 10.5439 28.935 11.6317C30.6162 12.7195 31.9388 14.2662 32.903 16.2719C33.8672 18.2775 34.3493 20.6571 34.3493 23.4105ZM31.3826 23.4105C31.3826 21.15 31.0159 19.2421 30.2824 17.6868C29.5572 16.1316 28.5724 14.9546 27.3281 14.1557C26.0919 13.3569 24.7075 12.9575 23.1746 12.9575C21.6418 12.9575 20.2533 13.3569 19.0089 14.1557C17.7727 14.9546 16.788 16.1316 16.0545 17.6868C15.3293 19.2421 14.9667 21.15 14.9667 23.4105C14.9667 25.6711 15.3293 27.579 16.0545 29.1343C16.788 30.6895 17.7727 31.8665 19.0089 32.6654C20.2533 33.4642 21.6418 33.8636 23.1746 33.8636C24.7075 33.8636 26.0919 33.4642 27.3281 32.6654C28.5724 31.8665 29.5572 30.6895 30.2824 29.1343C31.0159 27.579 31.3826 25.6711 31.3826 23.4105Z'
        fill='#171717'
      />
      <path
        d='M39.8872 24.9607C39.7388 23.6689 39.1372 22.6661 38.0824 21.9522C37.0276 21.2383 35.7338 20.8814 34.201 20.8814C33.0802 20.8814 32.0995 21.0684 31.259 21.4423C30.4266 21.8162 29.7756 22.3304 29.3059 22.9848C28.8444 23.6392 28.6136 24.3828 28.6136 25.2156C28.6136 25.9125 28.7743 26.5116 29.0957 27.013C29.4254 27.5059 29.8456 27.9181 30.3566 28.2496C30.8675 28.5725 31.4032 28.8402 31.9636 29.0527C32.5239 29.2566 33.039 29.4223 33.5087 29.5498L36.0799 30.2637C36.7391 30.4422 37.4726 30.6886 38.2802 31.0031C39.096 31.3175 39.8748 31.7467 40.6165 32.2906C41.3664 32.826 41.9845 33.5144 42.4707 34.3557C42.9569 35.197 43.2 36.2296 43.2 37.4534C43.2 38.8641 42.8415 40.1389 42.1246 41.2777C41.4159 42.4165 40.3775 43.3216 39.0095 43.9929C37.6498 44.6643 35.9975 45 34.0526 45C32.2396 45 30.6697 44.6983 29.343 44.0949C28.0244 43.4915 26.9861 42.6502 26.2279 41.5709C25.478 40.4916 25.0536 39.2381 24.9547 37.8103H28.1192C28.2016 38.7961 28.523 39.612 29.0834 40.2579C29.652 40.8953 30.3689 41.3712 31.2342 41.6856C32.1078 41.9916 33.0472 42.1445 34.0526 42.1445C35.2228 42.1445 36.2735 41.9491 37.2048 41.5581C38.136 41.1587 38.8735 40.6063 39.4174 39.9009C39.9613 39.1871 40.2333 38.3542 40.2333 37.4024C40.2333 36.5355 39.9984 35.8302 39.5287 35.2863C39.059 34.7424 38.4409 34.3005 37.6745 33.9605C36.9081 33.6206 36.0799 33.3231 35.1899 33.0682L32.0748 32.1504C30.097 31.564 28.5312 30.7269 27.3775 29.6391C26.2238 28.5513 25.6469 27.1278 25.6469 25.3686C25.6469 23.9069 26.0301 22.6321 26.7965 21.5443C27.5712 20.448 28.6095 19.5981 29.9116 18.9948C31.2219 18.3829 32.6846 18.0769 34.2998 18.0769C35.9315 18.0769 37.3819 18.3786 38.651 18.982C39.9201 19.5769 40.9255 20.3927 41.6672 21.4296C42.4171 22.4664 42.8127 23.6434 42.8539 24.9607H39.8872Z'
        fill='#171717'
      />
      <path
        d='M59.8634 35.7499L55.2 19.2259H58.4192L61.3964 31.3688H61.5497L64.7286 19.2259H67.6574L70.8444 31.3768H70.9897L73.9669 19.2259H77.1861L72.5226 35.7499H69.5696L66.2616 24.1557H66.1325L62.8164 35.7499H59.8634Z'
        fill='#171717'
      />
      <path
        d='M79.2617 35.7499V19.2259H82.255V26.2212H89.9119V19.2259H92.9133V35.7499H89.9119V28.7304H82.255V35.7499H79.2617Z'
        fill='#171717'
      />
      <path
        d='M110.934 27.4879C110.934 29.2683 110.6 30.7932 109.933 32.0626C109.272 33.3267 108.368 34.2949 107.222 34.9672C106.082 35.6396 104.789 35.9758 103.342 35.9758C101.895 35.9758 100.598 35.6396 99.4527 34.9672C98.3124 34.2895 97.4087 33.3186 96.7417 32.0546C96.0801 30.7852 95.7493 29.2629 95.7493 27.4879C95.7493 25.7075 96.0801 24.1853 96.7417 22.9212C97.4087 21.6518 98.3124 20.6809 99.4527 20.0086C100.598 19.3362 101.895 19 103.342 19C104.789 19 106.082 19.3362 107.222 20.0086C108.368 20.6809 109.272 21.6518 109.933 22.9212C110.6 24.1853 110.934 25.7075 110.934 27.4879ZM107.924 27.4879C107.924 26.2346 107.728 25.1777 107.335 24.3171C106.948 23.4511 106.41 22.7975 105.722 22.3565C105.033 21.91 104.24 21.6868 103.342 21.6868C102.443 21.6868 101.65 21.91 100.961 22.3565C100.273 22.7975 99.7324 23.4511 99.3397 24.3171C98.9524 25.1777 98.7588 26.2346 98.7588 27.4879C98.7588 28.7412 98.9524 29.8008 99.3397 30.6668C99.7324 31.5274 100.273 32.181 100.961 32.6274C101.65 33.0685 102.443 33.289 103.342 33.289C104.24 33.289 105.033 33.0685 105.722 32.6274C106.41 32.181 106.948 31.5274 107.335 30.6668C107.728 29.8008 107.924 28.7412 107.924 27.4879Z'
        fill='#171717'
      />
      <path d='M113.764 35.7499V19.2259H116.757V33.2406H124.035V35.7499H113.764Z' fill='#171717' />
      <path
        d='M126.633 35.7499V19.2259H137.38V21.7352H129.626V26.2212H136.823V28.7304H129.626V33.2406H137.444V35.7499H126.633Z'
        fill='#171717'
      />
      <path
        d='M149.414 23.7684C149.338 23.0638 149.021 22.5151 148.462 22.1225C147.908 21.7298 147.187 21.5335 146.299 21.5335C145.675 21.5335 145.14 21.6276 144.694 21.8159C144.247 22.0041 143.906 22.2596 143.669 22.5824C143.432 22.9051 143.311 23.2736 143.306 23.6877C143.306 24.032 143.384 24.3305 143.54 24.5833C143.701 24.8361 143.919 25.0513 144.194 25.2288C144.468 25.4009 144.772 25.5461 145.105 25.6645C145.439 25.7828 145.775 25.8823 146.114 25.963L147.663 26.3503C148.287 26.4955 148.887 26.6918 149.462 26.9393C150.043 27.1867 150.562 27.4987 151.019 27.8752C151.482 28.2517 151.848 28.7062 152.117 29.2387C152.386 29.7712 152.52 30.3952 152.52 31.1106C152.52 32.0788 152.273 32.9313 151.778 33.6682C151.283 34.3998 150.568 34.9726 149.632 35.3868C148.701 35.7956 147.574 36 146.251 36C144.965 36 143.849 35.801 142.903 35.4029C141.961 35.0049 141.224 34.424 140.692 33.6602C140.165 32.8964 139.88 31.9658 139.837 30.8685H142.782C142.825 31.4441 143.002 31.9228 143.314 32.3047C143.626 32.6866 144.032 32.9717 144.532 33.1599C145.038 33.3482 145.603 33.4423 146.227 33.4423C146.878 33.4423 147.448 33.3455 147.937 33.1519C148.432 32.9528 148.819 32.6785 149.099 32.3289C149.379 31.9739 149.521 31.5597 149.527 31.0864C149.521 30.6561 149.395 30.3011 149.147 30.0214C148.9 29.7363 148.553 29.4996 148.107 29.3113C147.666 29.1177 147.149 28.9456 146.558 28.795L144.678 28.3109C143.317 27.9612 142.241 27.4314 141.45 26.7214C140.665 26.006 140.272 25.0567 140.272 23.8733C140.272 22.8997 140.536 22.0472 141.063 21.3156C141.596 20.5841 142.319 20.0166 143.233 19.6132C144.148 19.2044 145.183 19 146.34 19C147.512 19 148.54 19.2044 149.422 19.6132C150.309 20.0166 151.006 20.5787 151.512 21.2995C152.017 22.0149 152.278 22.8379 152.294 23.7684H149.414Z'
        fill='#171717'
      />
      <path
        d='M157.044 35.7499H153.849L159.667 19.2259H163.362L169.187 35.7499H165.992L161.579 22.6146H161.45L157.044 35.7499ZM157.149 29.271H165.863V31.6754H157.149V29.271Z'
        fill='#171717'
      />
      <path d='M171.364 35.7499V19.2259H174.357V33.2406H181.635V35.7499H171.364Z' fill='#171717' />
      <path
        d='M184.233 35.7499V19.2259H194.98V21.7352H187.226V26.2212H194.423V28.7304H187.226V33.2406H195.044V35.7499H184.233Z'
        fill='#171717'
      />
    </svg>
  )
}
export default Logo
