import { useFormik } from 'formik'
import { useAppDispatch } from 'hooks/useStore'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { signUpSchema } from 'utils/validation'
import { ISignUpParams } from '../pages/SignUp/types'
import AuthorizationApi from '../services/AuthorizationApi'
import { URLS } from 'constants/urls'
import LocalStorage from 'utils/LocalStorage'
import { IOffer } from 'interfaces/IOffer'
import CartApi from 'modules/Cart/services/CartApi'

export const useSignUp = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phone: '',
      businessName: '',
    },
    validateOnMount: true,
    validationSchema: signUpSchema,
    onSubmit: async (values: ISignUpParams) => {
      const filteredObject = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(values).filter(([_, value]) => value !== '' && value !== null && value !== undefined),
      )
      await dispatch(AuthorizationApi.signUp(filteredObject))
        .unwrap()
        .then(() => {
          if (LocalStorage.getItem('cart') && LocalStorage.getItem('cart').length > 0) {
            const products = LocalStorage.getItem('cart')[0].products.map((product: IOffer) => ({
              productId: product.productId,
              quantity: product.quantity,
              price: product.price,
              size: product.size,
            }))
            dispatch(CartApi.getCurrentCart(products))
          }
        })
        .catch((error) => error)
    },
  })

  const navigateBackward = useCallback(() => {
    navigate(URLS.AUTH.LOGIN)
  }, [navigate])

  return { formik, navigateBackward }
}
