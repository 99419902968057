import { message, notification } from 'antd'
import { CheckCircleOutlined, InfoCircleFilled } from '@ant-design/icons'
import styled from 'styled-components'

import { glacier, tundora, errorRed, dodgerBlue } from 'constants/theme'
import { INotificationProps } from './types'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { NOTIFICATION_DURATION } from 'constants/constants'
import { ICssProperty } from 'interfaces/ICssProperty'

const SuccessIcon = styled(CheckCircleOutlined)`
  &.anticon-check-circle {
    color: ${glacier};
  }
`

const InfoIcon = styled(InfoCircleFilled)<ICssProperty>`
  &.anticon.anticon-info-circle {
    color: ${(props) => props.$color || dodgerBlue};
  }
`

export const useHideNotifications = () => {
  const location = useLocation()
  const [pathName, setPathName] = useState('')

  useEffect(() => {
    // we should hide all messages (toasts) after the route was changed
    if (pathName !== location.pathname) {
      message.destroy()
    }
    setPathName(location.pathname)
  }, [location, pathName])
}

export const openSuccessMessage = ({ content, onClose }: INotificationProps) => {
  const config = {
    content,
    duration: NOTIFICATION_DURATION,
    onClose,
    style: {
      color: tundora,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
    },
    icon: <SuccessIcon />,
  }
  message.success(config)
}

export const openErrorMessage = ({ content, onClose }: INotificationProps) => {
  const config = {
    content,
    duration: NOTIFICATION_DURATION,
    onClose,
    style: {
      color: errorRed,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
    },
  }
  message.error(config)
}

export const openInfoMessage = ({ content, onClose, $iconColor }: INotificationProps) => {
  const config = {
    content,
    duration: NOTIFICATION_DURATION,
    onClose,
    style: {
      color: tundora,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
    },

    icon: <InfoIcon $color={$iconColor} />,
  }
  message.info(config)
}

export const openNotificationMessage = ({
  message,
  description,
  placement,
  onClose,
  key,
  style,
  icon,
  closeIcon,
  duration = NOTIFICATION_DURATION,
}: INotificationProps) => {
  const config = {
    description,
    message,
    duration,
    onClose,
    style: {
      color: tundora,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      ...style,
    },
    placement,
    key,
    icon,
    closeIcon,
  }

  notification.open(config)
}
