export const authReducerName = 'auth'

export const AUTH_ACTION_TYPES = {
  LOGIN: authReducerName + '/login',
  LOGOUT: authReducerName + '/logout',
  SIGN_UP: authReducerName + '/sign-up',
  EDIT_PERSONAL_INFORMATION: authReducerName + '/edit-personal-information',
  RESET_PASSWORD: authReducerName + '/reset-password',
  CREATE_NEW_PASSWORD: authReducerName + '/create-new-password',
  CHECK_TOKEN_EXPIRATION: authReducerName + '/check-token-expiration',
  CHANGE_PASSWORD: authReducerName + '/change-password',
}
