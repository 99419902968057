import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { IProduct } from 'interfaces/IProduct'
import DefaultShoe from 'assets/default_shoe.png'
import { useAppSelector } from 'hooks/useStore'
import { ICartProduct } from 'interfaces/ICartProduct'
import { black, gallery } from 'constants/theme'
import TypographyText from 'components/TypographyText/TypographyText'
import { formatPrice } from 'utils/helpers'

const ProductLabel = ({ product }: { product: IProduct }) => {
  const { data } = useAppSelector((state) => state.cartReducer)
  const generateProductTitle = useMemo<string>(() => {
    let result = ''
    if (product.brand_name) {
      result = product.brand_name + ' '
    }
    result += product.name + ' '
    if (product.color) {
      result += product.color + ' '
    }
    if (product.gender) {
      result += `(${product.gender[0]})`
    }
    return result
  }, [product])

  const cartProducts = data
    .map((cart) => cart.products.filter((cartProduct: ICartProduct) => cartProduct.productId === product.id))
    .flat()

  const totalCartPrice = cartProducts.reduce((acc, cartProduct) => {
    return acc + cartProduct.quantity * cartProduct.price
  }, 0)

  return (
    <StyledItem>
      <StyledImg src={product.image_url || DefaultShoe} alt={product.name} />
      <StyledItemContainer>
        <StyledTitle>{generateProductTitle}</StyledTitle>
        <StyledSubTitle>{product.sku}</StyledSubTitle>
      </StyledItemContainer>
      {cartProducts.length > 0 && (
        <TotalPriceWrapper>
          <TypographyText $fontSize='16px' $lineHeight='19px' $width='fit-content' color={black}>
            Total ({formatPrice(totalCartPrice)})
          </TypographyText>
        </TotalPriceWrapper>
      )}
    </StyledItem>
  )
}

export default memo(ProductLabel)

const StyledItem = styled('div')`
  padding-left: 0;
  display: flex;
  align-items: center;
`

const StyledImg = styled('img')`
  width: 160px;
  height: 90px;
  margin-right: 12px;
  object-fit: cover;
`

const StyledItemContainer = styled('div')`
  flex-grow: 1;
`

const StyledTitle = styled('p')`
  margin: 0;
  text-align: left;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 18px;
  text-transform: uppercase;
  max-width: none;
`
const StyledSubTitle = styled('p')`
  margin: 0;
  font-weight: 400;
  text-align: left;
  margin-top: 0;
  font-size: 16px;
`
const TotalPriceWrapper = styled('div')`
  background: ${gallery};
  border-radius: 31px;
  padding: 8px 16px;
`
