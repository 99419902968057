import Input from 'components/Input/Input'
import TypographyText from 'components/TypographyText/TypographyText'
import styled from 'styled-components'
import React, { useState } from 'react'
import Button from 'components/Button/Button'
import { FormikHelpers, FormikProvider, useFormik } from 'formik'
import { resetPasswordSchema } from 'utils/validation'
import { black } from 'constants/theme'
import AuthorizationApi from 'modules/Auth/services/AuthorizationApi'
import { useAppDispatch } from 'hooks/useStore'
import Done from 'assets/Done'

const ForgotPassword = () => {
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const dispatch = useAppDispatch()
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnMount: true,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values: { email: string }) => {
      await dispatch(AuthorizationApi.resetPassword(values.email))
        .unwrap()
        .then(() => {
          setShowSuccessScreen(true)
        })
        .catch((error) => error)
    },
  })

  return (
    <>
      {!showSuccessScreen ? (
        <FormikProvider value={formik}>
          <Wrapper>
            <TypographyText color={black} $fontSize='38px' $lineHeight='46px' $textAlign='center'>
              Reset password
            </TypographyText>
            <TypographyText
              color={black}
              $fontSize='18px'
              $lineHeight='22px'
              $fontWeight='500'
              $textAlign='center'
              $margin='20px 0 44px 0'
            >
              We will email you a link that will allow you to create a new password
            </TypographyText>
            <Input
              $width='676px'
              $height='56px'
              $rowHeight='84px'
              name='email'
              $fontSize='16px'
              $fontWeight='400'
              $lineHeight='20px'
              $color={black}
              $withLabel
              label='Email'
            />
            <Button
              $fontWeight='700'
              $fontSize='20px'
              $lineHeight='24px'
              $width='676px'
              $height='48px'
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            >
              Send
            </Button>
          </Wrapper>
        </FormikProvider>
      ) : (
        <Wrapper>
          <Done />
          <TypographyText color={black} $fontSize='38px' $lineHeight='46px' $textAlign='center'>
            Email has been sent
          </TypographyText>
          <TypographyText
            color={black}
            $fontSize='18px'
            $lineHeight='22px'
            $fontWeight='500'
            $textAlign='center'
            $margin='20px 0 0 0'
          >
            Please follow the link that we sent on{' '}
            <TypographyText
              color={black}
              $fontSize='18px'
              $lineHeight='22px'
              $fontWeight='500'
              $textAlign='center'
              $textDecoration='underline'
              $textUnderlineOffset='4px'
            >
              {formik.values.email}
            </TypographyText>
          </TypographyText>
          <TypographyText color={black} $fontSize='18px' $lineHeight='22px' $fontWeight='500' $textAlign='center'>
            to create a new password
          </TypographyText>
        </Wrapper>
      )}
    </>
  )
}

export default ForgotPassword

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`
