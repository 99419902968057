import TypographyText from 'components/TypographyText/TypographyText'
import { silver, white } from 'constants/theme'
import { useAppDispatch } from 'hooks/useStore'
import React, { ChangeEvent, useCallback, useState } from 'react'
import styled from 'styled-components'
import CartApi from '../services/CartApi'
import Button from 'components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { EDeliveryMethod } from 'enums/EDeliveryMethod'
import CustomRadio from 'components/Radio/CustomRadio'
import CustomCheckbox from 'components/Checkbox/components/CustomCheckbox'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const FinalizeOffer = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [deliveryMethod, setDeliveryMethod] = useState<EDeliveryMethod>()
  const [takeAll, setTakeAll] = useState(false)
  const [agreed, setAgreed] = useState(false)
  const navigateToSuccessScreen = useCallback(() => navigate(URLS.DASHBOARD.CONFIRMED_CART), [navigate])

  const sendOffer = useCallback(() => {
    dispatch(
      CartApi.confirmCart({
        shipping: deliveryMethod === EDeliveryMethod.SHIPPING,
        takeAll,
        deliveryAddress: deliveryMethod === EDeliveryMethod.DROPOFF ? 'Dropped off' : 'Shipped',
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(CartApi.getCart())
        navigateToSuccessScreen()
      })
  }, [dispatch, deliveryMethod, navigateToSuccessScreen, takeAll])

  const handleShipping = (e: ChangeEvent<HTMLInputElement>) => {
    setDeliveryMethod(e.target.value as EDeliveryMethod)
  }
  const handleTakeAllCheckbox = (e: CheckboxChangeEvent) => {
    setTakeAll(e.target.checked)
  }
  const handleAgreedCheckbox = (e: CheckboxChangeEvent) => {
    setAgreed(e.target.checked)
  }

  return (
    <FinalizeOfferWrapper>
      <TypographyText $fontSize='24px' $lineHeight='29px' $fontWeight='700'>
        Choose a delivery method
      </TypographyText>
      <DeliveryOptions>
        <DeliveryOption>
          <CustomRadio
            checked={deliveryMethod === EDeliveryMethod.SHIPPING}
            onChange={handleShipping}
            value={EDeliveryMethod.SHIPPING}
          />

          <TypographyText $fontSize='16px' $lineHeight='20px'>
            Shipping
          </TypographyText>
        </DeliveryOption>
        <DeliveryOption>
          <CustomRadio
            checked={deliveryMethod === EDeliveryMethod.DROPOFF}
            value={EDeliveryMethod.DROPOFF}
            onChange={handleShipping}
          />

          <TypographyText $fontSize='16px' $lineHeight='20px'>
            Drop off (Secaucus, NJ 07940)
          </TypographyText>
        </DeliveryOption>
      </DeliveryOptions>
      <TakeAllWrapper>
        <CustomCheckbox name='takeAll' onChange={handleTakeAllCheckbox} checked={takeAll} />
        <TypographyText $fontSize='16px' $lineHeight='20px'>
          This is a `take all` offer.
        </TypographyText>
      </TakeAllWrapper>

      <AgreedWrapper>
        <CustomCheckbox name='agree' onChange={handleAgreedCheckbox} checked={agreed} />
        <TypographyText $fontSize='16px' $lineHeight='20px'>
          I have read and agree to the <a href='#'>Seller Guidelines</a> (required).
        </TypographyText>
      </AgreedWrapper>

      <Button
        $width='285px'
        $height='54px'
        $fontSize='20px'
        $lineHeight='24px'
        $fontWeight='700'
        onClick={sendOffer}
        $margin='40px 0 0 0'
        disabled={!deliveryMethod || !agreed}
      >
        Send offer
      </Button>
    </FinalizeOfferWrapper>
  )
}

export default FinalizeOffer

const FinalizeOfferWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background: ${white};
  padding: 20px;
  margin-top: 28px;
  width: 70vw;
`
const DeliveryOptions = styled('div')`
  display: flex;
  gap: 20px;
  margin: 40px 0 24px 0;
`
const TakeAllWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`

const AgreedWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
`

const DeliveryOption = styled('div')`
  background: ${white};
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${silver};
  width: 340px;
  height: 40px;
  align-items: center;
`
