import * as Yup from 'yup'

export const allowNonCyrillicSymbols = /^[ A-Za-z0-9~!@#№$%^&`'₴*()_\-+={}[\]|\\/:;“"’<,>.?]*$/
export const preventRepeatedCharacters =
  // eslint-disable-next-line no-useless-escape
  /^(?!-)(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'",<.>\/?]{2}).+$/i
export const emailRegex = /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const phoneNumberMaskRegExp = /^\d{3} \d{3} \d{4}$/

export const allowNumbersOnlyRegExp = /(\D)/g

const spacesRegex = /^\S*$/
export const matchLowerCase = new RegExp(/^(?=.*[a-z])/)
export const matchUpperCase = new RegExp(/^(?=.*[A-Z])/)
export const matchDigit = new RegExp(/^(?=.*\d)/)
export const matchSpecialCharacter = new RegExp(/^(?=.*[\W_])/)

// Allowed only ! - & symbols
const allowedSymbolsRegex = /^[a-zA-Z!&\s-]*$/

const onlySpacesRegex = /^\s*$/
const noNumbersRegex = /^[^0-9]*$/
const noEmojisRegex = /^(?:(?![\uD800-\uDFFF]).)*$/

export const emailValidation = Yup.string()
  .email('Incorrect email')
  .matches(emailRegex, 'Incorrect email')
  .matches(preventRepeatedCharacters, 'Incorrect email')
  .test('Incorrect email', 'Incorrect email', (value) => {
    if (value) {
      return Boolean(value.match(allowNonCyrillicSymbols))
    }
    return true
  })
  .required('Required field')

const passwordValidation = Yup.string()
  .required('This field is required')
  .matches(spacesRegex, 'Spaces are not allowed')
  .min(8, 'At least 8 characters in length')
  .matches(matchLowerCase, 'At least one lowercase letter')
  .matches(matchUpperCase, 'At least one uppercase letter')
  .matches(matchDigit, 'At least one digit')
  .matches(matchSpecialCharacter, 'At least one special character')

const nameValidation = Yup.string()
  .matches(noNumbersRegex, 'Numerics are not allowed')
  .matches(noEmojisRegex, 'Emojis are not allowed')
  .matches(allowedSymbolsRegex, 'Only letters, spaces, !, &, and - are allowed')
  .test('no-only-spaces', 'Input cannot contain only spaces', (value) => (value ? !onlySpacesRegex.test(value) : true))
  .max(50)

export const loginSchema = Yup.object().shape({
  email: emailValidation,
  password: Yup.string().required('Required field'),
})

export const signUpSchema = Yup.object().shape({
  firstName: nameValidation.required('Required field'),
  lastName: nameValidation.required('Required field'),
  phone: Yup.string()
    .trim()
    .matches(phoneNumberMaskRegExp, { message: 'Incorrect Phone Number' })
    .required('Required field'),
  email: emailValidation,
  password: passwordValidation,
  businessName: Yup.string(),
})

export const createNewPasswordSchema = Yup.object().shape({
  password: passwordValidation,
  confirmPassword: passwordValidation.oneOf([Yup.ref('password')], 'Passwords must match'),
})

export const resetPasswordSchema = Yup.object().shape({
  email: emailValidation,
})

export const contactInformationSchema = Yup.object().shape({
  firstName: nameValidation.required('Required field'),
  lastName: nameValidation.required('Required field'),
  phone: Yup.string()
    .trim()
    .matches(phoneNumberMaskRegExp, { message: 'Incorrect Phone Number' })
    .required('Required field'),
  email: emailValidation,
})

export const contactSchema = Yup.object().shape({
  fName: nameValidation.required('Required field'),
  lName: nameValidation.required('Required field'),
  phone: Yup.string()
    .trim()
    .matches(phoneNumberMaskRegExp, { message: 'Incorrect Phone Number' })
    .required('Required field'),
  email: emailValidation,
})

export const locationSchema = Yup.object().shape({
  locationName: Yup.string()
    .max(6)
    .test('no-only-spaces', 'Input cannot contain only spaces', (value) =>
      value ? !onlySpacesRegex.test(value) : true,
    )
    .required('Required field'),
  streetAddress1: Yup.string()
    .test('no-only-spaces', 'Input cannot contain only spaces', (value) =>
      value ? !onlySpacesRegex.test(value) : true,
    )
    .required('Required field'),
  streetAddress2: Yup.string()
    .test('no-only-spaces', 'Input cannot contain only spaces', (value) =>
      value ? !onlySpacesRegex.test(value) : true,
    )
    .notRequired(),
  city: nameValidation.required('Required field'),
  state: Yup.string().required('Required field'),
  zipCode: Yup.string()
    .matches(/^[0-9]{5}$/, 'Zip code can contain only 5 digits')
    .required('Required field'),
  contactFirstName: nameValidation.required('Required field'),
  contactLastName: nameValidation.required('Required field'),
  contactNumber: Yup.string()
    .trim()
    .matches(phoneNumberMaskRegExp, { message: 'Incorrect Phone Number' })
    .required('Required field'),
  contactEmail: emailValidation,
  isPrimary: Yup.boolean(),
})

export const addProductSchema = Yup.object().shape({
  orders: Yup.array().of(
    Yup.object().shape({
      products: Yup.array().of(
        Yup.object().shape({
          quantity: Yup.number().max(Yup.ref('maxQuantity'), 'Quantity must be less than 2').nullable(),
        }),
      ),
    }),
  ),
})

export const changePasswordSchema = Yup.object().shape({
  oldPassword: passwordValidation,
  newPassword: passwordValidation,
})
