import { Result } from 'antd'
import Button from 'components/Button/Button'
import { URLS } from 'constants/urls'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Row = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const NotFound = () => {
  const navigate = useNavigate()
  const navigateToHomePage = () => navigate(URLS.ROOT)
  return (
    <Row>
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={
          <Button $margin='0 auto' onClick={navigateToHomePage}>
            Back Home
          </Button>
        }
      />
    </Row>
  )
}

export default NotFound
