import { AnyAction, combineReducers, configureStore, createListenerMiddleware } from '@reduxjs/toolkit'

import authReducer from 'store/reducers/Auth/AuthSlice'
import commonReducer from 'store/reducers/Common/CommonSlice'
import productsReducer from 'store/reducers/Products/ProductSlice'
import cartReducer from 'store/reducers/Cart/CartSlice'
import { AUTH_ACTION_TYPES } from './reducers/Auth/action-types'

// import thunkInterceptorMiddleware from './middlewares/thunkMiddleware'

const appReducer = combineReducers({
  authReducer,
  commonReducer,
  productsReducer,
  cartReducer,
})

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === `${AUTH_ACTION_TYPES.LOGOUT}/fulfilled`) {
    state = undefined
  }
  return appReducer(state, action)
}

const listenerMiddleware = createListenerMiddleware()

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(listenerMiddleware.middleware),
    // .concat(thunkInterceptorMiddleware)
  })

export const store = setupStore()

export type RootState = ReturnType<typeof appReducer>

export type AppStore = ReturnType<typeof setupStore>

export type AppDispatch = AppStore['dispatch']
