import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { productsReducerName } from './action-types'
import ProductsApi from 'modules/Products/services/ProductsApi'
import { IProduct } from 'interfaces/IProduct'
import FileUploaderApi from 'utils/FileUploaderApi'

export interface ProductsState {
  data: IProduct[]
  hasMore: boolean
  loading: boolean
  page: number
  search: string
}

export const initialState: ProductsState = {
  data: [],
  hasMore: false,
  loading: false,
  search: '',
  page: 1,
}

export const productsSlice = createSlice({
  name: productsReducerName,
  initialState,
  reducers: {
    setSearchValue(state, { payload }: PayloadAction<string>) {
      state.search = payload
    },
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ProductsApi.searchProducts.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(
      ProductsApi.searchProducts.fulfilled.type,
      (state, { payload }: PayloadAction<{ products: IProduct[]; page: number; search: string }>) => {
        if (+payload.page === 1) {
          state.data = payload.products
        } else {
          state.data.push(...payload.products)
        }

        state.page = +payload.page
        state.search = payload.search
        state.loading = false
      },
    )
    builder.addCase(ProductsApi.searchProducts.rejected.type, (state) => {
      state.loading = false
    })
    builder.addCase(FileUploaderApi.uploadChunk.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(FileUploaderApi.uploadChunk.fulfilled.type, (state) => {
      state.loading = false
    })
    builder.addCase(FileUploaderApi.uploadChunk.rejected.type, (state) => {
      state.loading = false
    })
  },
})
export const productsActions = productsSlice.actions

export default productsSlice.reducer
