import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cartReducerName } from './action-types'
import CartApi from 'modules/Cart/services/CartApi'
import { ICart } from 'interfaces/ICart'
import ProductsApi from 'modules/Products/services/ProductsApi'
import LocalStorage from 'utils/LocalStorage'

export interface CartState {
  data: ICart[]
  totalPrice: number
  totalQty: number
  loading: boolean
}

export const initialState: CartState = {
  data: LocalStorage.getItem('cart') || [],
  totalPrice: LocalStorage.getItem('totalPrice'),
  totalQty: LocalStorage.getItem('totalQty'),
  loading: false,
}

export const cartSlice = createSlice({
  name: cartReducerName,
  initialState,
  reducers: {
    addProductsToCart: (state, { payload }: PayloadAction<CartState>) => {
      state.data.length > 0 ? state.data[0].products.push(...payload.data[0].products) : (state.data = payload.data)
      state.totalPrice = state.totalPrice + payload.totalPrice
      state.totalQty = Number(state.totalQty) + Number(payload.totalQty)

      LocalStorage.setItem('cart', state.data)
      LocalStorage.setItem('totalPrice', Number(state.totalPrice))
      LocalStorage.setItem('totalQty', Number(state.totalQty))
    },
    editProductsInCart: (state, { payload }: PayloadAction<CartState>) => {
      const updatedProductId = payload.data[0].products.find((product) => product)?.productId
      const updatedProducts = state.data[0].products.filter((product) => product.productId === updatedProductId)

      const qtyToSubtract = updatedProducts.reduce((sum, product) => sum + product.quantity, 0)
      const priceToSubtract = updatedProducts.reduce((sum, product) => sum + product.price, 0)

      const qtyToAdd = payload.data[0].products.reduce((sum, product) => sum + product.quantity, 0)
      const priceToAdd = payload.data[0].products.reduce((sum, product) => sum + product.price, 0)

      const products = state.data[0].products.filter((product) => product.productId !== updatedProductId)

      state.data[0].products = [...products, ...payload.data[0].products]
      state.totalPrice = state.totalPrice - priceToSubtract + priceToAdd
      state.totalQty = state.totalQty - qtyToSubtract + qtyToAdd
      LocalStorage.setItem('cart', state.data)
      LocalStorage.setItem('totalPrice', Number(state.totalPrice))
      LocalStorage.setItem('totalQty', Number(state.totalQty))
    },
    deleteProductInCart: (state, { payload }: PayloadAction<{ deletedProductId: string }>) => {
      const deletedProducts = state.data[0].products.filter((product) => product.productId === payload.deletedProductId)

      const qtyToSubtract = deletedProducts.reduce((sum, product) => sum + product.quantity, 0)
      const priceToSubtract = deletedProducts.reduce((sum, product) => sum + product.price, 0)
      const products = state.data[0].products.filter((product) => product.productId !== payload.deletedProductId)

      state.data[0].products = products
      state.totalPrice -= priceToSubtract
      state.totalQty -= qtyToSubtract
      LocalStorage.setItem('cart', state.data)
      LocalStorage.setItem('totalPrice', Number(state.totalPrice))
      LocalStorage.setItem('totalQty', Number(state.totalQty))
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CartApi.getCart.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(CartApi.getCart.fulfilled.type, (state, { payload }: PayloadAction<CartState>) => {
      state.data = payload.data
      state.totalPrice = payload.totalPrice
      state.totalQty = payload.totalQty
      state.loading = false
    })
    builder.addCase(CartApi.getCart.rejected.type, (state) => {
      state.loading = false
    })

    builder.addCase(CartApi.addOffersToCart.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(CartApi.addOffersToCart.fulfilled.type, (state, { payload }: PayloadAction<CartState>) => {
      state.data = payload.data
      state.totalPrice = payload.totalPrice
      state.totalQty = payload.totalQty
      state.loading = false
    })
    builder.addCase(CartApi.addOffersToCart.rejected.type, (state) => {
      state.loading = false
    })

    builder.addCase(CartApi.updateCart.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(CartApi.updateCart.fulfilled.type, (state, { payload }: PayloadAction<CartState>) => {
      state.data = payload.data
      state.totalPrice = payload.totalPrice
      state.totalQty = payload.totalQty
      state.loading = false
    })
    builder.addCase(CartApi.updateCart.rejected.type, (state) => {
      state.loading = false
    })

    builder.addCase(CartApi.deleteCart.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(CartApi.deleteCart.fulfilled.type, (state, { payload }: PayloadAction<CartState>) => {
      state.data = payload.data
      state.totalPrice = payload.totalPrice
      state.totalQty = payload.totalQty
      state.loading = false
    })
    builder.addCase(CartApi.deleteCart.rejected.type, (state) => {
      state.loading = false
    })

    builder.addCase(CartApi.confirmCart.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(CartApi.confirmCart.fulfilled.type, (state) => {
      state.loading = false
    })
    builder.addCase(CartApi.confirmCart.rejected.type, (state) => {
      state.loading = false
    })

    builder.addCase(CartApi.getCurrentCart.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(CartApi.getCurrentCart.fulfilled.type, (state, { payload }: PayloadAction<CartState>) => {
      state.data = payload.data
      state.totalPrice = payload.totalPrice
      state.totalQty = payload.totalQty
      state.loading = false
    })
    builder.addCase(CartApi.getCurrentCart.rejected.type, (state) => {
      state.loading = false
    })

    builder.addCase(ProductsApi.convertProductsToCart.pending.type, (state) => {
      state.loading = true
    })
    builder.addCase(ProductsApi.convertProductsToCart.fulfilled.type, (state) => {
      state.loading = false
    })
    builder.addCase(ProductsApi.convertProductsToCart.rejected.type, (state) => {
      state.loading = false
    })
  },
})
export const cartActions = cartSlice.actions

export default cartSlice.reducer
