import React from 'react'

const InvalidLink = () => {
  return (
    <svg width='250' height='201' viewBox='0 0 250 201' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M92 140.5C79.8497 140.5 70 130.874 70 119C70 107.126 79.8497 97.5 92 97.5C92.5167 97.5 93.0292 97.5174 93.537 97.5517C93.1842 95.5878 93 93.5654 93 91.5C93 72.7223 108.222 57.5 127 57.5C141.991 57.5 154.716 67.202 159.239 80.6695C160.31 80.5575 161.398 80.5 162.5 80.5C179.345 80.5 193 93.9315 193 110.5C193 126.241 180.675 139.227 165 140.478V140.5H108.508H92ZM103.996 140.5H97.0314H103.996Z'
        fill='#F9F9F9'
      />
      <path
        d='M103.996 140.5H97.0314M92 140.5C79.8497 140.5 70 130.874 70 119C70 107.126 79.8497 97.5 92 97.5C92.5167 97.5 93.0292 97.5174 93.537 97.5517C93.1842 95.5878 93 93.5654 93 91.5C93 72.7223 108.222 57.5 127 57.5C141.991 57.5 154.716 67.202 159.239 80.6695C160.31 80.5575 161.398 80.5 162.5 80.5C179.345 80.5 193 93.9315 193 110.5C193 126.241 180.675 139.227 165 140.478V140.5H108.508H92Z'
        stroke='black'
        stroke-width='2.5'
        stroke-linecap='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M116.612 64.8426C116.612 97.0657 145.633 123.618 183 127.203C178.317 132.961 171.086 136.868 162.847 137.481V137.5H95.7431C87.6013 137.5 74 134.07 74 119.048C74 104.027 84.5742 100.597 95.7431 100.597C96.218 100.597 96.6891 100.612 97.1559 100.641C96.8316 98.9556 96.7746 97.2184 96.6623 95.4474C95.9038 83.4842 101.123 68.407 116.63 63.5C116.618 63.9473 116.612 64.3944 116.612 64.8426ZM127.116 115.258C124.078 115.258 121.614 117.692 121.614 120.695C121.614 123.698 124.078 126.132 127.116 126.132C130.155 126.132 132.618 123.698 132.618 120.695C132.618 117.692 130.155 115.258 127.116 115.258Z'
        fill='#EFEFEF'
      />
      <path
        d='M127.5 126.5C130.538 126.5 133 124.038 133 121C133 117.962 130.538 115.5 127.5 115.5C124.462 115.5 122 117.962 122 121C122 124.038 124.462 126.5 127.5 126.5Z'
        stroke='black'
        stroke-width='2.5'
      />
      <path
        d='M112 109.5L119 104.007L112 98.7776'
        stroke='black'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M143 109.5L136 104.007L143 98.7776'
        stroke='black'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M137 67.5C143.509 69.2226 148.648 74.3129 150.44 80.7932'
        stroke='#262626'
        stroke-width='2.5'
        stroke-linecap='round'
      />
      <path
        d='M158 50.5C159.657 50.5 161 49.1569 161 47.5C161 45.8431 159.657 44.5 158 44.5C156.343 44.5 155 45.8431 155 47.5C155 49.1569 156.343 50.5 158 50.5Z'
        stroke='#262626'
        stroke-width='2'
      />
      <path
        d='M189 66.5C190.657 66.5 192 65.1569 192 63.5C192 61.8431 190.657 60.5 189 60.5C187.343 60.5 186 61.8431 186 63.5C186 65.1569 187.343 66.5 189 66.5Z'
        fill='#262626'
      />
      <path
        d='M165.757 58.2573L174.116 66.6156M174.243 58.2573L165.884 66.6156L174.243 58.2573Z'
        stroke='#262626'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M71.4038 76.0962L77.4038 82.0962M77.4038 76.0962L71.4038 82.0962L77.4038 76.0962Z'
        stroke='#262626'
        stroke-width='2.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M85 69.5C86.6569 69.5 88 68.1569 88 66.5C88 64.8431 86.6569 63.5 85 63.5C83.3431 63.5 82 64.8431 82 66.5C82 68.1569 83.3431 69.5 85 69.5Z'
        fill='#262626'
      />
    </svg>
  )
}

export default InvalidLink
