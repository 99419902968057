import styled from 'styled-components'

import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'
import { ILogoutProps } from 'components/Modal/types'

const ModalTitle = styled('div')`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`

const Logout = (props: ILogoutProps) => {
  return (
    <Modal
      open={props.open}
      width={props.width}
      onOk={props.onOk}
      onCancel={props.onCancel}
      hideHeader={props.hideHeader}
      hideFooter={props.hideFooter}
      bodyStyle={{ padding: '0' }}
    >
      <ModalTitle>Log out</ModalTitle>
      <div>Are you sure you want to log out?</div>
      <ButtonWrapper>
        <Button
          variant='outlined'
          $fontSize={'14px'}
          $width={'78px'}
          $height={'32px'}
          $margin={'0 8px 0 0'}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <Button $fontSize={'14px'} $width={'56px'} $height={'32px'} onClick={props.onOk}>
          Yes
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

export default Logout
