import { RcFile } from 'antd/es/upload'

export interface IUploadedFile {
  file: RcFile
  percent: number
  status: EFileStatus
  deleteFile: () => void
  tryAgain: () => void
}

export enum EFileStatus {
  DEFAULT,
  PROGRESS,
  COMPLETED,
  FAIL,
}
