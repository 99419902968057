import { ENDPOINTS } from 'constants/endpoints'
import httpClient from 'utils/HttpClient'
import LocalStorage from 'utils/LocalStorage'
import { ILoginParams } from '../pages/Login/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AUTH_ACTION_TYPES } from 'store/reducers/Auth/action-types'
import { ISignUpParams } from '../pages/SignUp/types'
import { IEditContactInformationPayload } from './types'
import { ICreateNewPasswordParams } from '../pages/CreateNewPassword/types'
import { IChangePasswordPayload } from 'modules/Profile/types'

export default class AuthorizationApi {
  static login = createAsyncThunk(AUTH_ACTION_TYPES.LOGIN, async (data: ILoginParams, thunkApi) => {
    const response = await httpClient.request({
      url: ENDPOINTS.AUTH.LOGIN,
      method: 'POST',
      data,
    })

    LocalStorage.setItem('user', response.data)
    LocalStorage.setItem('isAuthenticated', true)

    return thunkApi.fulfillWithValue(response.data)
  })

  static logout = createAsyncThunk(AUTH_ACTION_TYPES.LOGOUT, (_, thunkApi) => {
    httpClient.request({
      url: ENDPOINTS.AUTH.LOGOUT,
      method: 'POST',
      shouldInterceptError: false,
    })

    LocalStorage.removeItem('user')
    LocalStorage.removeItem('isAuthenticated')

    return thunkApi.fulfillWithValue(null)
  })

  static signUp = createAsyncThunk(AUTH_ACTION_TYPES.SIGN_UP, async (data: ISignUpParams, thunkApi) => {
    const response = await httpClient.request({
      url: ENDPOINTS.AUTH.SIGN_UP,
      method: 'POST',
      data,
    })

    LocalStorage.setItem('user', response.data)
    LocalStorage.setItem('isAuthenticated', true)

    return thunkApi.fulfillWithValue(response.data)
  })

  static editContactInformation = createAsyncThunk(
    AUTH_ACTION_TYPES.EDIT_PERSONAL_INFORMATION,
    async (data: IEditContactInformationPayload, thunkApi) => {
      const response = await httpClient.request({
        url: ENDPOINTS.AUTH.EDIT_CONTACT_INFORMATION(data.id),
        method: 'PATCH',
        data: data.contactInformation,
      })

      LocalStorage.setItem('user', response.data)

      return thunkApi.fulfillWithValue(response.data)
    },
  )

  static resetPassword = createAsyncThunk(AUTH_ACTION_TYPES.RESET_PASSWORD, async (email: string, thunkApi) => {
    const response = await httpClient.request({
      url: ENDPOINTS.AUTH.RESET_PASSWORD,
      method: 'POST',
      data: { email },
    })

    return thunkApi.fulfillWithValue(response.data)
  })

  static checkTokenExpiration = createAsyncThunk(
    AUTH_ACTION_TYPES.CHECK_TOKEN_EXPIRATION,
    async (token: string, thunkApi) => {
      const response = await httpClient.request({
        url: ENDPOINTS.AUTH.CHECK_TOKEN_EXPIRATION(token),
        method: 'GET',
      })

      return thunkApi.fulfillWithValue(response.data)
    },
  )

  static createNewPassword = createAsyncThunk(
    AUTH_ACTION_TYPES.CREATE_NEW_PASSWORD,
    async (data: ICreateNewPasswordParams, thunkApi) => {
      const { password, confirmPassword, token } = data
      const response = await httpClient.request({
        url: ENDPOINTS.AUTH.CREATE_NEW_PASSWORD,
        method: 'POST',
        data: { password, confirmPassword },
        params: { token },
      })

      return thunkApi.fulfillWithValue(response.data)
    },
  )

  static changePassword = createAsyncThunk(
    AUTH_ACTION_TYPES.CHANGE_PASSWORD,
    async (data: IChangePasswordPayload, thunkApi) => {
      const response = await httpClient.request({
        url: ENDPOINTS.AUTH.CHANGE_PASSWORD,
        method: 'PATCH',
        data,
      })

      LocalStorage.removeItem('user')
      LocalStorage.removeItem('isAuthenticated')

      return thunkApi.fulfillWithValue(response.data)
    },
  )
}
