/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from 'components/Button/Button'
import Table from 'components/Table/Table'
import { FormikProvider, useFormik } from 'formik'
import React, { memo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { renderColumns } from '../utils/renderColumns'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { formatPrice } from 'utils/helpers'

import { IProduct } from 'interfaces/IProduct'

import { IProductItem } from 'interfaces/IProductItem'
import CartApi from 'modules/Cart/services/CartApi'
import { IOffer } from 'interfaces/IOffer'
import { ICartProduct } from 'interfaces/ICartProduct'
import useDeleteProduct from '../hooks/useDeleteProduct'
import Icon from 'components/Icon/Icon'
import { CheckOutlined } from '@ant-design/icons'
import { black, white } from 'constants/theme'
import DeleteIcon from 'assets/DeleteIcon'
import EditIcon from 'assets/EditIcon'
import ProductsApi from '../services/ProductsApi'
import { cartActions } from 'store/reducers/Cart/CartSlice'
import { openInfoMessage } from 'components/Notification/Notification'

const ProductTable = ({ product }: { product: IProduct }) => {
  const dispatch = useAppDispatch()
  const { data } = useAppSelector((state) => state.cartReducer)
  const { isAuthenticated } = useAppSelector((state) => state.authReducer)

  const cartProducts = data
    .map((cart) => cart.products.filter((cartProduct: ICartProduct) => cartProduct.productId === product.id))
    .flat()

  const initialValues: { offers: IOffer[]; productId: string; totalPrice: number } = {
    offers: product.items.map((item: IProductItem) => {
      const cartProduct = cartProducts.find((cartProduct) => cartProduct.size === item.size)
      return {
        quantity: cartProduct?.quantity || null,
        size: item.size,
        price: cartProduct?.price || null,
        productId: product.id,
        productVariantId: item.id,
        sku: product.sku,
        details: product.name || product.details,
      }
    }),
    productId: product.id,
    totalPrice: 0,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: { offers: IOffer[]; productId: string; totalPrice: number }) => {
      const offers = values.offers.filter((offer) => offer.quantity && offer.price)
      const productId = values.productId
      if (isAuthenticated) {
        dispatch(CartApi.addOffersToCart({ offers, productId }))
          .unwrap()
          .then(() => openInfoMessage({ content: 'Product was added to Offers' }))
      } else {
        dispatch(ProductsApi.convertProductsToCart(offers))
          .unwrap()
          .then((payload) => {
            dispatch(cartActions.addProductsToCart(payload))
            openInfoMessage({ content: 'Product was added to offers' })
          })
      }
    },
  })

  const { values, setFieldValue } = formik

  const clearFields = useCallback(() => {
    product.items.forEach((_, idx) => {
      setFieldValue(`offers.${idx}.quantity`, '')
      setFieldValue(`offers.${idx}.price`, '')
    })
  }, [setFieldValue, product.items])

  const { openRemoveProductsModal, DeleteProductsModal, isEditMode, turnOnEditMode, turnOffEditMode } =
    useDeleteProduct({
      productId: product.id,
      clearFields,
    })

  const editProduct = useCallback(() => {
    const offers = values.offers.filter((offer) => offer.quantity && offer.price)
    const productId = values.productId
    if (isAuthenticated) {
      dispatch(CartApi.addOffersToCart({ offers, productId }))
        .unwrap()
        .then(() => {
          openInfoMessage({ content: 'Product was changed in Offers' })
          turnOffEditMode()
        })
    } else {
      dispatch(ProductsApi.convertProductsToCart(offers))
        .unwrap()
        .then((payload) => {
          turnOffEditMode()
          openInfoMessage({ content: 'Product was changed in Offers' })
          dispatch(cartActions.editProductsInCart(payload))
        })
    }
  }, [dispatch, values.offers, values.productId, turnOffEditMode, isAuthenticated])

  const calculateTotalPrice = useCallback(() => {
    const totalPrice = values.offers.reduce((acc, offer: IOffer) => {
      const total = offer.price && offer.quantity ? offer.price * offer.quantity : 0
      return acc + total
    }, 0)
    setFieldValue('totalPrice', totalPrice)
  }, [values.offers, setFieldValue])

  useEffect(() => {
    calculateTotalPrice()
  }, [calculateTotalPrice])

  const totalPrice = values.totalPrice > 0 && `(${formatPrice(values.totalPrice)})`

  const disableAddToCartButton =
    !values.totalPrice ||
    values.offers.some((offer) => (offer.quantity && !offer.price) || (!offer.quantity && offer.price))

  const disableSaveEditCartButton = values.offers.some(
    (offer) => (offer.quantity && !offer.price) || (!offer.quantity && offer.price),
  )

  return (
    <TableWrapper>
      {cartProducts.length <= 0 && (
        <Button
          $alignSelf='flex-end'
          $width={'fit-content'}
          $height='40px'
          onClick={formik.handleSubmit}
          $padding='10px 24px'
          $margin='0 20px 0 0'
          disabled={disableAddToCartButton}
        >
          Add to offer {totalPrice}
        </Button>
      )}
      {cartProducts.length > 0 && !isEditMode && (
        <Button $alignSelf='flex-end' $width={'96px'} $height='40px' onClick={turnOnEditMode} $margin='0 20px 0 0'>
          <EditIcon fill={white} />
          Edit
        </Button>
      )}
      {cartProducts.length > 0 && isEditMode && (
        <EditModeButtonWrapper>
          <Button $width={'103px'} $height='40px' onClick={editProduct} disabled={disableSaveEditCartButton}>
            <Icon Component={CheckOutlined} $color={disableSaveEditCartButton ? black : white} $fontSize='14px' />
            Save
          </Button>
          <Button $width={'127px'} $height='40px' onClick={openRemoveProductsModal} variant='outlined'>
            <DeleteIcon width='18px' height='18px' fill={black} />
            Remove
          </Button>
        </EditModeButtonWrapper>
      )}

      <FormikProvider value={formik}>
        <Table
          columns={renderColumns({ offers: values.offers, disabled: !isEditMode && cartProducts.length > 0 })}
          className='products'
          dataSource={product.items}
          scroll={{ x: '100%' }}
          rowKey={'size'}
          pagination={false}
        />
      </FormikProvider>
      {DeleteProductsModal}
    </TableWrapper>
  )
}

export default memo(ProductTable)

const TableWrapper = styled('div')`
  max-width: calc(100vw - 30px);
  overflow: auto;
  display: flex;
  flex-direction: column;

  .products {
    padding: 0;
    margin: 20px 0;
  }
`

const EditModeButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin: 0 20px 20px 0;
`
