import { Component, ComponentType, ReactNode } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ReloadOutlined, WarningOutlined } from '@ant-design/icons'

import { codGray, white } from 'constants/theme'
import Icon from 'components/Icon/Icon'
import TypographyText from 'components/TypographyText/TypographyText'
import Button from 'components/Button/Button'

interface State {
  hasError: boolean
  error: Error | null
}

interface IErrorBoundaryProps {
  children?: ReactNode
  location?: Location
}

export const ERROR_BOUNDARY_MESSAGE = {
  label: 'Sorry… There was an error.',
  description: 'If reloading the page does not solve the issue, please contact support.',
}

function withRouter<T>(Component: ComponentType<T>) {
  function ComponentWithRouterProp(props: T) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    return <Component {...props} location={location} params={params} navigate={navigate} />
  }

  return ComponentWithRouterProp
}

const Container = styled('div')`
  background: ${white};
  display: flex;
  flex-grow: 1;
  border-radius: 11px;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 72px);
`

const Wrapper = styled('div')`
  max-width: 840px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

class ErrorBoundary extends Component<IErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
    error: null,
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  componentDidUpdate(prevProps: IErrorBoundaryProps) {
    if (this.props?.location !== prevProps?.location) {
      this.setState({ hasError: false, error: null })
    }
  }

  handleReload = () => window.location.reload()

  public render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Wrapper>
            <Icon
              Component={WarningOutlined}
              $width='82px'
              $height='82px'
              $color={codGray}
              $margin='0 0 30px 0'
              $cursor='initial'
            />
            <TypographyText $fontWeight='400' $color={codGray} $fontSize='20px' $display='block'>
              {ERROR_BOUNDARY_MESSAGE.label}
            </TypographyText>
            <TypographyText $fontWeight='400' $color={codGray} $fontSize='20px' $textAlign='center'>
              {ERROR_BOUNDARY_MESSAGE.description}
            </TypographyText>
            <Button
              $display='flex'
              $alignItems='center'
              $width='320px'
              $height='40px'
              $margin='60px 0 0 0'
              onClick={this.handleReload}
            >
              <Icon Component={ReloadOutlined} />
              <TypographyText>Reload</TypographyText>
            </Button>
          </Wrapper>
        </Container>
      )
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
