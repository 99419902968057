/* eslint-disable @typescript-eslint/no-explicit-any */
import { RightOutlined } from '@ant-design/icons'
import Icon from 'components/Icon/Icon'
import TypographyText from 'components/TypographyText/TypographyText'
import { URLS } from 'constants/urls'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { black, gray } from 'constants/theme'
import Logout from 'components/Modal/components/Logout/Logout'
import { FormikProvider, useFormik } from 'formik'
import { allowNumbersOnlyRegExp, contactInformationSchema } from 'utils/validation'
import Input from 'components/Input/Input'
import Button from 'components/Button/Button'
import AuthorizationApi from 'modules/Auth/services/AuthorizationApi'
import { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { IContactInformation, IEditContactInformationPayload } from 'modules/Auth/services/types'
import ChangePasswordForm from './components/ChangePasswordForm'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`
const NavigationWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 40px;
`

const ProfileWrapper = styled('div')`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  margin-top: 40px;
`
const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`

const Profile = () => {
  const navigate = useNavigate()

  const navigateBackward = () => navigate(URLS.DASHBOARD.PRODUCTS)

  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.authReducer)

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const openLogoutModal = () => setIsLogoutModalOpen(true)
  const closeLogoutModal = () => setIsLogoutModalOpen(false)

  const logout = () => {
    dispatch(AuthorizationApi.logout())
      .unwrap()
      .then(() => closeLogoutModal())
  }
  const editContactInformation = useCallback(
    (values: IContactInformation, { resetForm }: any) => {
      const data = {
        id: user?.id,
        contactInformation: {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
        },
      } as IEditContactInformationPayload
      dispatch(AuthorizationApi.editContactInformation(data))
        .unwrap()
        .then(() => resetForm({ values }))
    },
    [dispatch, user?.id],
  )

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
      email: user?.email,
    },
    validateOnMount: true,
    validationSchema: contactInformationSchema,
    onSubmit: editContactInformation,
  })

  return (
    <Wrapper>
      <NavigationWrapper>
        <TypographyText
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='22px'
          $color={gray}
          onClick={navigateBackward}
          $cursor='pointer'
        >
          HOMEPAGE
        </TypographyText>
        <Icon Component={RightOutlined} $color={gray} $fontSize='16px' onClick={navigateBackward} />
        <TypographyText $fontSize='16px' $fontWeight='400' $lineHeight='22px' color={black}>
          MY PROFILE
        </TypographyText>
      </NavigationWrapper>

      <TypographyText $fontSize='32px' $fontWeight='600' $lineHeight='38px' color={black}>
        My Profile
      </TypographyText>

      <ProfileWrapper>
        <FormikProvider value={formik}>
          <FormWrapper>
            <Input
              $width='500px'
              $height='56px'
              $rowHeight='84px'
              name='firstName'
              $fontSize='18px'
              $fontWeight='500'
              $lineHeight='22px'
              $color={black}
              label='First name'
            />
            <Input
              $width='500px'
              $height='56px'
              $rowHeight='84px'
              name='lastName'
              $fontSize='18px'
              $fontWeight='500'
              $lineHeight='22px'
              $color={black}
              label='Last name'
            />
            <Input
              type='phone'
              $allowedSymbols={allowNumbersOnlyRegExp}
              label='Phone'
              $width='500px'
              $height='56px'
              $rowHeight='84px'
              $addonsHeight='56px'
              name='phone'
              $fontSize='16px'
              $fontWeight='400'
              $lineHeight='20px'
              $color={black}
            />
            <Input
              $width='500px'
              $height='56px'
              $rowHeight='84px'
              name='email'
              $fontSize='18px'
              $fontWeight='500'
              $lineHeight='22px'
              $color={black}
              label='Email'
              disabled
            />
          </FormWrapper>
          <Button
            $height='48px'
            $width='500px'
            $fontWeight='700'
            onClick={formik.handleSubmit}
            $margin='0 0 24px 0'
            disabled={!(formik.dirty && formik.isValid)}
          >
            Save
          </Button>
          <ChangePasswordForm />
          <Button
            $height='48px'
            $width='500px'
            $fontWeight='700'
            $margin='24px 0 0 0'
            variant='outlined'
            onClick={openLogoutModal}
          >
            Log out
          </Button>
          <Logout
            onOk={logout}
            onCancel={closeLogoutModal}
            open={isLogoutModalOpen}
            width={400}
            hideHeader
            hideFooter
          />
        </FormikProvider>
      </ProfileWrapper>
    </Wrapper>
  )
}

export default Profile
