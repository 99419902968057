import React, { useCallback } from 'react'
import Button from 'components/Button/Button'
import styled from 'styled-components'
import { FormikHelpers, FormikProvider, useFormik } from 'formik'
import { loginSchema } from 'utils/validation'
import TypographyText from 'components/TypographyText/TypographyText'
import { black, mineShaft } from 'constants/theme'
import Input from 'components/Input/Input'
import AuthorizationApi from 'modules/Auth/services/AuthorizationApi'
import { ILoginParams } from './types'
import { useAppDispatch } from 'hooks/useStore'
import { NavLink, useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`

const LinKWrapper = styled('div')`
  width: 676px;
  display: flex;
  align-items: flex-start;
`
const StyledNavLink = styled(NavLink)`
  color: ${mineShaft};
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
`

const Login = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnMount: true,
    validationSchema: loginSchema,
    onSubmit: async (values: ILoginParams, { resetForm }: FormikHelpers<ILoginParams>) => {
      await dispatch(AuthorizationApi.login(values))
        .unwrap()
        .then(() => {
          resetForm()
        })
        .catch((error) => error)
    },
  })
  const { handleSubmit, isValid } = formik

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && isValid) {
        handleSubmit()
        const target = e.target as HTMLInputElement
        target.blur()
      }
    },
    [handleSubmit, isValid],
  )

  const navigateToCreateAccount = useCallback(() => {
    navigate(URLS.AUTH.CREATE_ACCOUNT)
  }, [navigate])

  return (
    <Wrapper>
      <FormikProvider value={formik}>
        <TypographyText color={black} $fontSize='38px' $lineHeight='45px' $textAlign='center' $margin='0 0 30px 0'>
          Login
        </TypographyText>
        <Input
          $width='676px'
          $height='56px'
          $rowHeight='86px'
          name='email'
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='20px'
          $color={black}
          $withLabel
          label='Email'
          onKeyDown={handleKeyDown}
        />

        <Input
          $width='676px'
          $height='56px'
          $rowHeight='86px'
          name='password'
          type='password'
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='20px'
          $color={black}
          $withLabel
          label='Password'
          onKeyDown={handleKeyDown}
        />

        <LinKWrapper>
          <StyledNavLink to={URLS.AUTH.FORGOT_PASSWORD}>Forgot the password?</StyledNavLink>
        </LinKWrapper>

        <Button
          $fontWeight='700'
          $fontSize='20px'
          $lineHeight='24px'
          $width='676px'
          $height='48px'
          $margin='30px 0 0 0'
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
        >
          Login
        </Button>
        <Button
          variant='outlined'
          $fontWeight='700'
          $fontSize='20px'
          $lineHeight='24px'
          $width='676px'
          $height='48px'
          $margin='12px 0 0 0'
          onClick={navigateToCreateAccount}
        >
          Sign up
        </Button>
      </FormikProvider>
    </Wrapper>
  )
}

export default Login
