import { Divider as AntdDivider } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'

import { IDividerProps } from './types'

const StyledDivider = styled(AntdDivider)<IDividerProps>`
  margin: ${(props) => (props.$margin ? props.$margin : 0)};
  border-top: ${(props) =>
    props.$transparent
      ? '1px solid transparent'
      : props.$borderTop
      ? props.$borderTop
      : '1px solid rgba(0, 0, 0, 0.06)'};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  min-width: ${(props) => props.$minWidth && props.$minWidth};
  top: 0;
  bottom: 0;
`

const Divider: FC<IDividerProps> = (props) => {
  return <StyledDivider {...props} />
}

export default Divider
