const OfferIcon = ({ width, height }: { width: string; height: string }) => (
  <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M18.9998 3.33333C18.9998 3.24493 18.9647 3.16014 18.9022 3.09763C18.8397 3.03512 18.7549 3 18.6665 3H9.33317C8.36071 3 7.42808 3.38631 6.74045 4.07394C6.05281 4.76158 5.6665 5.69421 5.6665 6.66667V25.3333C5.6665 26.3058 6.05281 27.2384 6.74045 27.9261C7.42808 28.6137 8.36071 29 9.33317 29H22.6665C23.639 29 24.5716 28.6137 25.2592 27.9261C25.9469 27.2384 26.3332 26.3058 26.3332 25.3333V12.196C26.3332 12.1076 26.298 12.0228 26.2355 11.9603C26.173 11.8978 26.0882 11.8627 25.9998 11.8627H19.9998C19.7346 11.8627 19.4803 11.7573 19.2927 11.5698C19.1052 11.3822 18.9998 11.1279 18.9998 10.8627V3.33333ZM19.9998 16.3333C20.2651 16.3333 20.5194 16.4387 20.7069 16.6262C20.8945 16.8138 20.9998 17.0681 20.9998 17.3333C20.9998 17.5985 20.8945 17.8529 20.7069 18.0404C20.5194 18.228 20.2651 18.3333 19.9998 18.3333H11.9998C11.7346 18.3333 11.4803 18.228 11.2927 18.0404C11.1052 17.8529 10.9998 17.5985 10.9998 17.3333C10.9998 17.0681 11.1052 16.8138 11.2927 16.6262C11.4803 16.4387 11.7346 16.3333 11.9998 16.3333H19.9998ZM19.9998 21.6667C20.2651 21.6667 20.5194 21.772 20.7069 21.9596C20.8945 22.1471 20.9998 22.4015 20.9998 22.6667C20.9998 22.9319 20.8945 23.1862 20.7069 23.3738C20.5194 23.5613 20.2651 23.6667 19.9998 23.6667H11.9998C11.7346 23.6667 11.4803 23.5613 11.2927 23.3738C11.1052 23.1862 10.9998 22.9319 10.9998 22.6667C10.9998 22.4015 11.1052 22.1471 11.2927 21.9596C11.4803 21.772 11.7346 21.6667 11.9998 21.6667H19.9998Z'
      fill='black'
    />
    <path
      d='M21 3.76535C21 3.52001 21.2573 3.36401 21.448 3.51735C21.6098 3.64801 21.7533 3.80001 21.8787 3.97335L25.896 9.56935C25.9867 9.69735 25.888 9.86268 25.7307 9.86268H21.3333C21.2449 9.86268 21.1601 9.82756 21.0976 9.76505C21.0351 9.70254 21 9.61775 21 9.52935V3.76535Z'
      fill='black'
    />
  </svg>
)
export default OfferIcon
