export const DragAndDropIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3060_49560)'>
        <path
          d='M6.99947 18C5.75174 18 4.55512 17.5258 3.67285 16.6819C2.79058 15.838 2.29492 14.6934 2.29492 13.5C2.29492 12.3065 2.79058 11.1619 3.67285 10.318C4.55512 9.47406 5.75174 8.99995 6.99947 8.99995C7.29415 7.68713 8.15623 6.53343 9.39605 5.79267C10.01 5.42587 10.6981 5.1715 11.4212 5.04406C12.1444 4.91663 12.8883 4.91863 13.6106 5.04995C14.3328 5.18127 15.0193 5.43934 15.6308 5.80943C16.2422 6.17952 16.7667 6.65437 17.1742 7.20688C17.5818 7.75939 17.8644 8.37873 18.006 9.02955C18.1476 9.68036 18.1454 10.3499 17.9995 11H18.9995C19.9277 11 20.818 11.3687 21.4743 12.0251C22.1307 12.6815 22.4995 13.5717 22.4995 14.5C22.4995 15.4282 22.1307 16.3184 21.4743 16.9748C20.818 17.6312 19.9277 18 18.9995 18H17.9995'
          stroke='#515151'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M9 15L12 12L15 15' stroke='#515151' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12 12V21' stroke='#515151' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_3060_49560'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
