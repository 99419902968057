import styled from 'styled-components'

import { black } from 'constants/theme'
import { ILabelProps } from './types'

const StyledLabel = styled('label')<ILabelProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.$color || black};
  display: block;
  margin: ${(props) => props.$margin || '0 0 8px 0'};
`

const Label = ({ children, htmlFor, required, $margin, $color }: ILabelProps) => {
  return (
    <StyledLabel htmlFor={htmlFor} required={required} $margin={$margin} $color={$color}>
      {children}
    </StyledLabel>
  )
}

export default Label
