import { FieldProps, getIn } from 'formik'

export const useFormikErrors = ({ form, field }: FieldProps) => {
  const showErrorMessage =
    (form.touched[field.name] || getIn(form.touched, field.name)) &&
    (getIn(form.errors, field.name) || form.errors[field.name])

  const errorMessage = getIn(form.errors, field.name) as string

  return {
    showErrorMessage,
    errorMessage,
  }
}
