export const URLS = {
  ROOT: '/',
  AUTH: {
    LOGIN: '/login',
    CREATE_ACCOUNT: '/create-account',
    FORGOT_PASSWORD: '/forgot-password',
    SET_PASSWORD: '/auth/password/update',
  },
  DASHBOARD: {
    PRODUCTS: '/products',
    PROFILE: '/profile',
    CART: '/cart',
    CONFIRMED_CART: '/cart-confirmed',
  },
}
