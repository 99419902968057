import { black, white, silver, silverChalise, emperor, cadetBlue } from 'constants/theme'
import { InputNativeOnChangeProp, InputProps } from 'components/Input/types'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { styled as muiStyled } from '@mui/material/styles'
import { ICssProperty } from 'interfaces/ICssProperty'
import styled from 'styled-components'

const Wrapper = styled('div')<ICssProperty>`
  width: ${(props) => props.$width};

  ${(props) => {
    return props.$responsiveStyles
  }};
`
const StyledTextField = muiStyled(TextField)((props: InputProps) => ({
  '&.MuiTextField-root': {
    width: '100%',
  },
  '& label.Mui-focused': {
    color: `${black}`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: `${cadetBlue}`,
  },
  '&:focused .MuiInputLabel-formControl': {
    color: black,
  },
  '& .MuiOutlinedInput-root': {
    background: white,
    '& input': {
      height: props.$height || '100%',
      padding: props.$padding || '0 18px',
      border: props.$border || 'none',
      background: props.$background || white,
      borderRadius: props.$borderRadius || '4px',
      fontWeight: props.$fontWeight || 400,
      color: props.$color || black,
      margin: props.$margin,
      fontSize: props.$fontSize || '16px',
      lineHeight: props.$lineHeight || '20px',
      '-webkit-text-fill-color': black,
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 1000px white inset',
        '-webkit-text-fill-color': props.$color || 'black',
      },
    },
    '& input:disabled': {
      cursor: 'not-allowed',
    },
    '& label': {
      color: black,
      Opacity: 1,
    },
    '& fieldset': {
      borderColor: `${props.$borderColor} || ${silverChalise}`,
      borderRadius: props.$borderRadius,
    },

    '&:hover fieldset': {
      borderColor: `${emperor}`,
    },
    '&:Mui-focused input': {
      borderColor: `${emperor}`,
    },
    '&:disabled fieldset': {
      borderColor: silver,
      color: silver,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${emperor}`,
    },
  },
}))

const CustomInput = ({
  type,
  value,
  onChange,
  disabled,
  name,
  placeholder,
  minRows,
  maxRows,
  onBlur,
  error,
  helperText,
  startAdornment,
  endAdornment,
  onKeyDown,
  $width,
  $responsiveStyles,
  ...rest
}: InputProps & InputNativeOnChangeProp & TextFieldProps) => {
  return (
    <Wrapper $width={$width} $responsiveStyles={$responsiveStyles}>
      <StyledTextField
        id='outlined-basic'
        variant='outlined'
        minRows={minRows}
        maxRows={maxRows}
        onKeyDown={onKeyDown}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={(e) =>
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault()
            },
            { passive: false },
          )
        }
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        inputProps={{ min: 1, step: 1 }}
        InputProps={{
          startAdornment,
          endAdornment,
        }}
        {...rest}
      />
    </Wrapper>
  )
}

export default CustomInput
