import { Radio, RadioProps, styled as muiStyled } from '@mui/material'
import { black } from 'constants/theme'

const StyledRadio = muiStyled(Radio)(() => ({
  '&.Mui-checked': {
    color: black,
    width: 24,
    height: 24,
  },
  '&.MuiRadio-root': {
    padding: 0,
  },
}))

const CustomRadio = ({ checked, onChange, required, value, ...props }: RadioProps) => {
  return <StyledRadio checked={checked} onChange={onChange} required={required} value={value} {...props} />
}

export default CustomRadio
