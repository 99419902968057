import { Checkbox as AntCheckbox } from 'antd'
import { gray, black, alabaster } from 'constants/theme'
import styled from 'styled-components'

import { ICheckboxtNativeOnChangeProp, ICustomCheckboxProps } from '../types'
import { ICssProperty } from 'interfaces/ICssProperty'

const StyledCheckbox = styled(AntCheckbox)<ICssProperty>`
  margin: ${(props) => props.$margin};

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border: ${(props) => props.$border || `1px solid ${gray}`};
  }

  .ant-checkbox-checked::after {
    border: 1px solid ${gray};
  }

  .ant-checkbox .ant-checkbox-inner {
    border: ${(props) => props.$border || `1px solid ${gray}`};
    border-radius: ${(props) => props.$borderRadius || '2px'};
    background: ${alabaster};
    outline: none;
  }

  .ant-checkbox-input:focus {
    border: 1px solid ${black};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border: 1px solid ${black};
    border-radius: ${(props) => props.$borderRadius || '2px'};
    background: ${black};
    outline: none;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background: white;
  }
  & .ant-checkbox-checked::after {
    border-radius: ${(props) => props.$borderRadius || '2px'};
  }
  & .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    background-color: ${black};
  }
  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: ${black};
  }
  & .ant-checkbox-checked .ant-checkbox-inner:after {
    opacity: ${(props) => (props.$borderRadius === '50%' ? 0 : 1)};
  }
`
const CustomCheckbox = ({
  checked,
  onChange,
  disabled,
  className,
  name,
  $margin,
  $border,
  ...rest
}: ICustomCheckboxProps & ICheckboxtNativeOnChangeProp) => {
  return (
    <StyledCheckbox
      name={name}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
      className={className}
      $margin={$margin}
      $border={$border}
      {...rest}
    />
  )
}

export default CustomCheckbox
