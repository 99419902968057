class AbortRequest {
  controller: AbortController | null
  signal: AbortSignal | undefined

  constructor() {
    this.controller = null
    this.signal = undefined
  }

  init() {
    this.controller = new AbortController()
    this.signal = this.controller.signal
  }

  abort() {
    if (this.controller) {
      this.controller.abort()
      this.controller = null
    }
  }
}

export default AbortRequest
