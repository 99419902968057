import { Divider } from 'antd'
import Button from 'components/Button/Button'
import { black, silver, white } from 'constants/theme'
import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { InputAdornment } from '@mui/material'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import CustomInput from 'components/Input/components/CustomInput'
import { useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { UploadInventory } from 'assets/UploadInventoryIcon'
import UploadInventoryModal from 'components/Modal/components/Logout/UploadInventory/UploadInventoryModal'
import { useUploadFileModal } from '../hooks/useUploadFile'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import LocalStorage from 'utils/LocalStorage'
import CartApi from 'modules/Cart/services/CartApi'
import Icon from 'components/Icon/Icon'
import { IOffer } from 'interfaces/IOffer'

const EmptyState = styled('div')`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  // background: ${white};
  .ant-divider-horizontal.ant-divider-with-text::before,
  .ant-divider-horizontal.ant-divider-with-text::after {
    background: ${silver};
  }
  .ant-divider-horizontal.ant-divider-with-text {
    margin: 20px 0;
  }
  .MuiAutocomplete-listbox {
    height: 390px;
    max-height: 390px;
    overflow: hidden;
  }
`

const Container = styled('div')`
  height: 258px;
  text-align: center;
`

const Title = styled('div')`
  margin-bottom: 44px;
  font-size: 38px;
  font-weight: 400;
  line-height: 46px;
`

const ProductsSearch = () => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAppSelector((state) => state.authReducer)

  const { handleUploadCancel, handleUploadOk, uploadModalState, openUploadInventoryModal } = useUploadFileModal()
  const navigateToCart = useCallback(() => navigate(URLS.DASHBOARD.CART), [navigate])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (event: KeyboardEvent<Element> | any): void => {
    if (event.key === 'Enter' && searchTerm) {
      navigate(URLS.DASHBOARD.PRODUCTS + `?search=${searchTerm}`)
    }
  }

  const onSearch = () => {
    navigate(URLS.DASHBOARD.PRODUCTS + `?search=${searchTerm}`)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const resetSearch = useCallback(() => {
    setSearchTerm('')
  }, [])

  useEffect(() => {
    isAuthenticated &&
      dispatch(CartApi.getCart())
        .unwrap()
        .then((payload) => {
          if (LocalStorage.getItem('cart')) {
            // Check if cart is already initialized
            if (payload.data && payload.data.length > 0) {
              LocalStorage.removeItem('cart')
              LocalStorage.removeItem('totalQty')
              LocalStorage.removeItem('totalPrice')
            } else {
              const products = LocalStorage.getItem('cart')[0].products.map((product: IOffer) => ({
                productId: product.productId,
                quantity: product.quantity,
                price: product.price,
                size: product.size,
              }))
              dispatch(CartApi.getCurrentCart(products))
                .unwrap()
                .then(() => navigateToCart())
            }
          }
        })
  }, [isAuthenticated, dispatch, navigateToCart])

  return (
    <EmptyState>
      <Container>
        <Title>What are you selling?</Title>
        <CustomInput
          $height='56px'
          $width='676px'
          name='search'
          label='Search'
          onKeyDown={handleKeyDown}
          onChange={handleSearch}
          value={searchTerm}
          endAdornment={searchTerm && <Icon Component={CloseOutlined} $color={black} onClick={resetSearch} />}
          startAdornment={
            <InputAdornment position='start'>
              <SearchOutlined />
            </InputAdornment>
          }
        />
        <Divider plain>OR</Divider>
        <Button
          variant='text'
          $height='48px'
          $fontSize='20px'
          $fontWeight='700'
          $background={white}
          onClick={openUploadInventoryModal}
        >
          <UploadInventory fill={black} /> Upload xlsx or csv file
        </Button>
        <Button $height='48px' $fontSize='20px' $fontWeight='700' $margin='10px 0 0' onClick={onSearch}>
          Search
        </Button>
      </Container>
      <UploadInventoryModal
        onOk={handleUploadOk}
        onCancel={handleUploadCancel}
        open={uploadModalState}
        width={750}
        hideHeader
        hideFooter
      />
    </EmptyState>
  )
}

export default ProductsSearch
