import { URLS } from 'constants/urls'
import ConfirmedCart from 'modules/Cart/pages/ConfirmedCart'
import Profile from 'modules/Profile/Profile'

const appRoutes = [
  {
    path: URLS.DASHBOARD.PROFILE,
    Component: Profile,
  },
  {
    path: URLS.DASHBOARD.CONFIRMED_CART,
    Component: ConfirmedCart,
  },
]

export default appRoutes
