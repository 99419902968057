import { memo, useMemo } from 'react'
import styled from 'styled-components'
import DefaultShoe from 'assets/default_shoe.png'
import { ICartProduct } from 'interfaces/ICartProduct'

const StyledItem = styled('div')`
  padding-left: 0;
  display: flex;
  align-items: center;
`

const StyledImg = styled('img')`
  margin-right: 12px;
  object-fit: cover;
  width: 160px;
  height: 90px;
`

const StyledItemContainer = styled('div')`
  flex-grow: 1;
`

const StyledTitle = styled('p')`
  margin: 0;
  text-align: left;
  font-weight: 500;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 18px;
  text-transform: uppercase;
  max-width: none;
`
const StyledSubTitle = styled('p')`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
`

const CartProductLabel = ({ product }: { product: ICartProduct }) => {
  const generateProductTitle = useMemo<string>(() => {
    let result = ''
    if (product.brand) {
      result = product.brand + ' '
    }
    result += product.name + ' '
    if (product.color) {
      result += product.color + ' '
    }
    if (product.gender) {
      result += `(${product.gender[0]})`
    }
    return result
  }, [product])

  return (
    <StyledItem>
      <StyledImg src={product.img || DefaultShoe} alt={product.name} />
      <StyledItemContainer>
        <StyledTitle>{generateProductTitle}</StyledTitle>
        <StyledSubTitle>{product.sku}</StyledSubTitle>
      </StyledItemContainer>
    </StyledItem>
  )
}

export default memo(CartProductLabel)
