import Done from 'assets/Done'
import Button from 'components/Button/Button'
import TypographyText from 'components/TypographyText/TypographyText'
import { URLS } from 'constants/urls'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const ConfirmedCart = () => {
  const navigate = useNavigate()
  const navigateToHomepage = useCallback(() => navigate(URLS.DASHBOARD.PRODUCTS), [navigate])
  return (
    <Wrapper>
      <TypographyText $fontSize='56px' $lineHeight='67px' $textAlign='center'>
        Thank you!
      </TypographyText>
      <TypographyText $fontSize='18px' $lineHeight='22px' $textAlign='center'>
        We’ve received your offer. An Account Rep will be in touch within 24-48 hours.
      </TypographyText>
      <Done />
      <Button
        $width='285px'
        $height='54px'
        $fontSize='20px'
        $lineHeight='24px'
        $fontWeight='700'
        onClick={navigateToHomepage}
      >
        Go to homepage
      </Button>
    </Wrapper>
  )
}

export default ConfirmedCart

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  height: calc(100vh - 72px);
`
