import { FormikHelpers, FormikProvider, useFormik } from 'formik'
import { changePasswordSchema } from 'utils/validation'
import { IChangePasswordPayload } from '../types'
import { useAppDispatch } from 'hooks/useStore'
import AuthorizationApi from 'modules/Auth/services/AuthorizationApi'
import styled from 'styled-components'
import { black } from 'constants/theme'
import Input from 'components/Input/Input'
import Button from 'components/Button/Button'
import { openSuccessMessage } from 'components/Notification/Notification'

const ChangePasswordForm = () => {
  const dispatch = useAppDispatch()
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validateOnMount: true,
    validationSchema: changePasswordSchema,
    onSubmit: async (values: IChangePasswordPayload, { resetForm }: FormikHelpers<IChangePasswordPayload>) => {
      await dispatch(AuthorizationApi.changePassword(values))
        .unwrap()
        .then(() => {
          resetForm()
          openSuccessMessage({ content: 'Password has been successfully changed' })
        })
    },
  })
  const { handleSubmit, isValid, dirty } = formik

  return (
    <FormikProvider value={formik}>
      <Wrapper>
        <Input
          $width='500px'
          $height='56px'
          $rowHeight='80px'
          name='oldPassword'
          type='password'
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='20px'
          $color={black}
          $withLabel
          label='Old Password'
        />
        <Input
          $width='500px'
          $height='56px'
          $rowHeight='80px'
          name='newPassword'
          type='password'
          $fontSize='16px'
          $fontWeight='400'
          $lineHeight='20px'
          $color={black}
          $withLabel
          label='New Password'
        />
        <Button $height='48px' $width='500px' $fontWeight='700' onClick={handleSubmit} disabled={!(dirty && isValid)}>
          Confirm new password
        </Button>
      </Wrapper>
    </FormikProvider>
  )
}

export default ChangePasswordForm

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`
