import { black } from 'constants/theme'
import React from 'react'

const EditIcon = ({ fill = black }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
      <path
        d='M2.75 14.55V17.0833C2.75 17.3166 2.93333 17.5 3.16667 17.5H5.7C5.80833 17.5 5.91667 17.4583 5.99167 17.375L15.0917 8.2833L11.9667 5.15829L2.875 14.25C2.79167 14.3333 2.75 14.4333 2.75 14.55ZM17.5083 5.86663C17.5856 5.78953 17.6469 5.69796 17.6887 5.59715C17.7305 5.49634 17.752 5.38827 17.752 5.27913C17.752 5.16999 17.7305 5.06192 17.6887 4.96111C17.6469 4.8603 17.5856 4.76872 17.5083 4.69163L15.5583 2.74163C15.4812 2.66438 15.3897 2.60309 15.2889 2.56127C15.188 2.51945 15.08 2.49792 14.9708 2.49792C14.8617 2.49792 14.7536 2.51945 14.6528 2.56127C14.552 2.60309 14.4604 2.66438 14.3833 2.74163L12.8583 4.26663L15.9833 7.39163L17.5083 5.86663Z'
        fill={fill}
      />
    </svg>
  )
}

export default EditIcon
