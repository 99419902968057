const MyProfileIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <path
        d='M15.9987 16C18.9454 16 21.332 13.6134 21.332 10.6667C21.332 7.72004 18.9454 5.33337 15.9987 5.33337C13.052 5.33337 10.6654 7.72004 10.6654 10.6667C10.6654 13.6134 13.052 16 15.9987 16ZM15.9987 18.6667C12.4387 18.6667 5.33203 20.4534 5.33203 24V26.6667H26.6654V24C26.6654 20.4534 19.5587 18.6667 15.9987 18.6667Z'
        fill='#171717'
      />
    </svg>
  )
}

export default MyProfileIcon
