import { FieldProps } from 'formik'
import { useCallback } from 'react'
import { InputCustomChangeEvent } from 'components/Input/types'

interface IEventHandlers {
  onBlur?: (event: React.FocusEvent<HTMLElement, Element>) => void
  onChange?: (e: InputCustomChangeEvent) => void
  $pattern?: RegExp
}

const useInputHandlers = ({ onChange, $pattern, onBlur, form, field }: FieldProps & IEventHandlers) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if ($pattern && !event.target.value.match($pattern)) {
        const selectionStart = (event.target.selectionStart as number) - 1

        event.preventDefault()

        return window.requestAnimationFrame(() => {
          event.target.setSelectionRange(selectionStart, selectionStart)
        })
      }
      if (onChange) {
        onChange({
          name: field.name,
          value: event.target.value,
          setFieldValue: form.setFieldValue,
          setValues: form.setValues,
        })
      } else {
        field.onChange(event)
      }
    },
    [onChange, $pattern, field, form.setFieldValue, form.setValues],
  )

  const handleBlur = (event: React.FocusEvent<HTMLElement, Element>) => {
    if (onBlur) {
      onBlur(event)
    } else form.setTouched({ ...form.touched, [field.name]: true })
  }

  return {
    handleBlur,
    handleChange,
  }
}

export default useInputHandlers
