import { Upload, UploadProps } from 'antd'
import { memo } from 'react'
const { Dragger: AntDragger } = Upload

const Dragger = ({ children, onChange, accept, name, multiple, onDrop, beforeUpload }: UploadProps) => {
  return (
    <AntDragger
      beforeUpload={beforeUpload}
      onChange={onChange}
      accept={accept}
      onDrop={onDrop}
      multiple={multiple}
      name={name}
      showUploadList={false}
    >
      {children}
    </AntDragger>
  )
}

export default memo(Dragger)
