import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'

import { URLS } from 'constants/urls'
import Layout from 'modules/Layout/Layout'
import appRoutes from './AppRoutes'
import authRoutes from './AuthRoutes'
import { useAppSelector } from 'hooks/useStore'
import NotFound from 'components/ErrorBoundary/NotFound'
import Products from 'modules/Products/pages/Products'
import ProductsSearch from 'modules/Products/pages/ProductsSearch'
import Cart from 'modules/Cart/pages/Cart'

const Routes = () => {
  const { isAuthenticated } = useAppSelector((state) => state.authReducer)

  const protectedLoader = () => {
    if (!isAuthenticated) {
      return redirect(URLS.AUTH.LOGIN)
    }
    return null
  }

  const authLoader = () => {
    if (isAuthenticated) {
      return redirect(URLS.ROOT)
    }
    return null
  }

  const router = createBrowserRouter([
    {
      path: '/',
      Component: Layout,
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          Component: ProductsSearch,
        },
        {
          path: URLS.DASHBOARD.PRODUCTS,
          Component: Products,
        },
        {
          path: URLS.DASHBOARD.CART,
          Component: Cart,
        },

        {
          loader: protectedLoader,
          children: appRoutes,
        },
        {
          loader: authLoader,
          children: authRoutes,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

export default Routes
